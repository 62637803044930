import { observer } from 'mobx-react';
import { Flex } from 'antd';
import JobCard from './JobCard';
import { useEffect, useState } from 'react';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { useParams } from 'react-router-dom';
import { JobsDataType } from '../../../ types';

const UpdatedJobsPage = () => {
  const { id: workspaceId } = useParams();
  const [jobs, setJobs] = useState<JobsDataType[]>([]);

  useEffect(() => {
    if (!workspaceId) return;

    (async () => {
      try {
        const res = await StagesStore.getUpdatedJobs.call(workspaceId);
        setJobs(res.data.updatedJobs);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <Flex vertical gap={12}>
      {jobs.map((job) => (
        <JobCard key={job._id} {...job} />
      ))}
    </Flex>
  );
};

export default observer(UpdatedJobsPage);
