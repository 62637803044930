import { RadioChangeEvent, message } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { JobsDataType } from '../../../../ types';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { $class } from '../../../../../utils';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';
import SliderButton, { ButtonSubmitType } from '../../../SliderButton';
import Labels from '../../Kanban/Distribution/Labels';
import Accordion from '../Accordion';
import * as styles from './index.module.scss';
import { WorkspacesStore } from '../../../../../DataSource/Stores/Cabinet/WorkspacesStore';

interface JobItemProps extends JobsDataType {
  onClick: (job: JobsDataType) => void;
  isSelectedJob: boolean;
  submitBtnName?: string;
  submitBtnTypes?: ButtonSubmitType[];
  children?: ReactNode;
  className?: string;
}

const JobItem: FC<JobItemProps> = ({
  name,
  _id,
  onClick,
  jobId,
  targetDate,
  workspaceId,
  bitrixId2,
  comments,
  jiraId2,
  jobData,
  submitBtnName,
  children,
  submitBtnTypes,
  deliveryData,
  className,
  jobFor,
  labels,
  isSelectedJob = false,
  createdBy,
}) => {
  const [selectedStages, setSelectedStages] = useState('');
  const [selectedJobId, setSelectedJobId] = useState('');

  const onChange = (e: RadioChangeEvent) => {
    setSelectedStages(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    setSelectedJobId(_id);
  }, []);

  const onChangeStages = async () => {
    //console.log(selectedJobId, selectedStages, workspaceId);
    await StagesStore.jobInwork(workspaceId, _id);
    if (StagesStore.loadingJobInWorkError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.setLayoutPageJob('joblay');
      StagesStore.setTabsIndex(1);
      // StagesStore.getListJobsQueue(workspaceId, '66e194841725b77d117c4d57').catch((e) => {
      //   console.error(e);
      // });
    }
  };

  const onClickMarshrut = async () => {
    await StagesStore.jobInwork(workspaceId, _id);
    if (StagesStore.loadingJobInWorkError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.setLayoutPageJob('joblay');
      StagesStore.setTabsIndex(3);
      // StagesStore.getListJobsQueue(workspaceId, '66e194841725b77d117c4d57').catch((e) => {
      //   console.error(e);
      // });
    }
  };

  const dateCreate = dayjs(targetDate);

  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedBitrixId, setIsCopiedBitrixId] = useState(false);

  const textToCopy = jobId;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      message.info('Скопировано', 2);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  const handleCopyBitrixId = async () => {
    if (bitrixId2 !== undefined) {
      try {
        await navigator.clipboard.writeText(bitrixId2);
        setIsCopiedBitrixId(true);
        message.info('Bitrix ID скопировано', 2);
        setTimeout(() => setIsCopiedBitrixId(false), 2000);
      } catch (err) {
        console.error('Failed to copy Bitrix ID: ', err);
      }
    } else {
      console.error('Bitrix ID is undefined');
    }
  };

  const getDateTime = () => (
    <>
      {dayjs(targetDate).format('HH:mm')}
      <span className={styles.separator}> • </span>
      {dayjs(targetDate).format('DD / MM / YY')}
    </>
  );

  return (
    <>
      {!isSelectedJob ? (
        <div
          onClick={() =>
            onClick({
              name,
              _id,
              jobId,
              workspaceId,
              bitrixId2,
              jiraId2,
              comments,
              jobData,
              jobFor,
              targetDate,
              deliveryData,
              labels,
              createdBy,
            })
          }
          className={$class([])}
        >
          <div className={$class(styles.flex_bl, styles.cardGrid, className)}>
            {jobFor && <div className={styles.organizationChip}>{jobFor}</div>}

            <div className={styles.textGroup}>
              <p>Доставить:</p>
              <div className={$class(styles.textValue, 'line-clamp-3')}>
                {deliveryData?.deliveryPoint?.address}
              </div>
            </div>

            <div className={styles.textGroup}>
              <p>Забрать:</p>

              <div className={$class(styles.textValue, 'line-clamp-3')}>
                {deliveryData?.pickupPoint?.address}
              </div>
            </div>

            <div className={styles.flexGroup}>
              <div className={styles.textGroup}>
                <p>Срок:</p>
                <div className={styles.textValue}>{getDateTime()}</div>
              </div>
              <div className={styles.textGroup}>
                <p>Номер заказа:</p>
                <div className={styles.chip}>
                  #{jobId} <Icon slug="arrow_vertical" />
                </div>
              </div>
            </div>
            <Labels labels={labels} _id={''} name={''} />
          </div>
        </div>
      ) : (
        <div>
          {name && <p className={styles.name2}>{name}</p>}
          <div className={styles.propertiesGrid}>
            <p>Номер заказа:</p>
            <div className={styles.copyGroup}>
              <div className={styles.chip}>#{jobId}</div>
              <Button variant="outlined" icon="copy_icon" onClick={handleCopy} autoWidth />
            </div>
            <p>Дата и время:</p>
            <div>{getDateTime()}</div>
            {/*{createdBy && (*/}
            {/*  <>*/}
            {/*    <p>Автор:</p>*/}
            {/*    <p>{`${WorkspacesStore.members[createdBy].userId?.firstname} ${WorkspacesStore.members[createdBy].userId?.secondname}`}</p>*/}
            {/*  </>*/}
            {/*)}*/}
            <div className={styles.border}></div>
            <div className={styles.border}></div>
            <p>Подразделение:</p>
            <div className={styles.categ_text}>{jobFor}</div>
            <div className={styles.border}></div>
            <div className={styles.border}></div>

            {labels && labels.length > 0 ? (
              <>
                <p className={styles.name}>Метки:</p>
                <Labels labels={labels} _id={''} name={''} />
                <div className={styles.border}></div>
                <div className={styles.border}></div>{' '}
              </>
            ) : (
              ''
            )}

            <p>Забрать:</p>
            <div>{deliveryData?.pickupPoint?.address}</div>

            <p>Отправитель:</p>
            <div>
              {deliveryData?.pickupPoint?.contactData.map((item) => (
                <Fragment key={item.phone}>
                  <p>{item.name}</p>
                  <a href={`tel:${item.phone}`}>{item.phone}</a>
                </Fragment>
              ))}
            </div>

            <div className={styles.border}></div>
            <div className={styles.border}></div>
            <p>Доставить:</p>
            <div>{deliveryData?.deliveryPoint?.address}</div>

            <p>Получатель:</p>
            <div>
              {deliveryData?.deliveryPoint?.contactData.map((item) => (
                <Fragment key={item.phone}>
                  <p>{item.name}</p>
                  <a href={`tel:${item.phone}`}>{item.phone}</a>
                </Fragment>
              ))}
            </div>
            <div className={styles.border}></div>
            <div className={styles.border}></div>
            <p>Способ оплаты:</p>
            <div>{deliveryData?.paymentData?.method}</div>

            <p>Сумма:</p>
            <div>{deliveryData?.paymentData?.amount} ₸</div>
          </div>
          {jobData?.[0]?.text ? (
            <div className={styles.instraction_bl}>
              <span>Инструкция:</span>

              {jobData?.[0]?.text && (
                <p>
                  {jobData[0].text.split('\\n').map((line, i) => (
                    <Fragment key={i}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </p>
              )}
            </div>
          ) : (
            <div className={styles.separator} />
          )}
          {children}
          <div className={styles.colasp_bl}>
            <Accordion comm={comments} />
          </div>

          <SliderButton
            types={submitBtnTypes}
            className={styles.slideBtn}
            name={submitBtnName || 'Далее'}
            onClick={submitBtnName == 'Добавить в маршрут' ? onClickMarshrut : onChangeStages}
            disabled={StagesStore.loadingJobInWork}
          />
          {StagesStore.loadingJobInWork && <div>Загрузка...</div>}
        </div>
      )}
    </>
  );
};

export default observer(JobItem);
