import { observer } from 'mobx-react';
import { Flex, Modal } from 'antd';
import JobCard from './JobCard';
import { useEffect, useState } from 'react';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { useParams } from 'react-router-dom';
import Button from '../../../ui/Button';
import { $class, api } from '../../../../utils';
import toast from 'react-hot-toast';
import { JobsDataType } from '../../../ types';
import * as styles from './index.module.scss';

const NewJobsPage = () => {
  const { id: workspaceId } = useParams();
  const [selecting, setSelecting] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
  const [rejectedJobs, setRejectedJobs] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const stageId = '6707a3db598d48bda5860bfa';

  useEffect(() => {
    if (!workspaceId) return;

    StagesStore.getListJobsQueue(workspaceId, stageId).catch((e) => {
      console.error(e);
    });
  }, []);

  const handleAcceptSelectedJobs = async () => {
    setLoading(true);

    try {
      const { data } = await api.post(`/workspaces/${workspaceId}/jobs/getToWorkMultiJobs`, {
        jobIds: selectedJobs,
      });

      setSelecting(false);
      setSelectedJobs([]);
      setRejectedJobs(data.rejected || []);
    } catch (e) {
      console.error(e);
      toast.error('Ошибка при принятии заказов');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectJob = (job: JobsDataType) => {
    if (selectedJobs.includes(job._id)) {
      setSelectedJobs(selectedJobs.filter((id) => id !== job._id));
    } else {
      setSelectedJobs([...selectedJobs, job._id]);
    }
  };

  return (
    <>
      <div className={styles.top}>
        <div>{selecting && <p>Выбрано: {selectedJobs.length}</p>}</div>

        {!selecting && (
          <Button variant="delivery_primary" autoWidth onClick={() => setSelecting(true)}>
            Выбрать несколько
          </Button>
        )}

        {selecting && (
          <Flex gap={8}>
            <Button
              variant="delivery_primary"
              autoWidth
              loading={loading}
              onClick={handleAcceptSelectedJobs}
            >
              Принять заказы
            </Button>
            <Button
              variant="delivery_primary"
              autoWidth
              onClick={() => {
                setSelecting(false);
                setSelectedJobs([]);
              }}
            >
              Отмена
            </Button>
          </Flex>
        )}
      </div>
      <Flex vertical gap={12}>
        {StagesStore.dataQueueList.map((job) => (
          <div
            key={job._id}
            className={$class(
              selecting && styles.selecting,
              selectedJobs.includes(job._id) && styles.selected
            )}
            onClick={() => handleSelectJob(job)}
          >
            <JobCard {...job} />
          </div>
        ))}
      </Flex>

      <Modal
        title="Не удалось принять заказы"
        centered
        cancelButtonProps={{ style: { display: 'none' } }}
        open={!!rejectedJobs.length}
        onOk={() => setRejectedJobs([])}
        onCancel={() => setRejectedJobs([])}
      >
        {rejectedJobs.map((jobId) => (
          <p key={jobId}>#{jobId}</p>
        ))}
      </Modal>
    </>
  );
};

export default observer(NewJobsPage);
