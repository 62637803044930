// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hGFVDgd0mg8k1nbiVanM{width:fit-content;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;display:flex;flex-direction:column;gap:12px;padding:12px}.hGFVDgd0mg8k1nbiVanM .LUQ6vz2YrvsVsJM5leU0{height:42px;width:42px;background-color:#f1f2f4;border:none;border-radius:8px}.hGFVDgd0mg8k1nbiVanM .LUQ6vz2YrvsVsJM5leU0.DkkqgPiCpgzWgJJSiPiB{background-color:#fbd6d6}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/LanzhouReviews/Nav/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qBAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA,CAEA,4CACE,WAAA,CACA,UAAA,CACA,wBAAA,CACA,WAAA,CACA,iBAAA,CAEA,iEACE,wBAAA","sourcesContent":[".nav{width:fit-content;background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;display:flex;flex-direction:column;gap:12px;padding:12px}.nav .navBtn{height:42px;width:42px;background-color:#f1f2f4;border:none;border-radius:8px}.nav .navBtn.active{background-color:#fbd6d6}"],"sourceRoot":""}]);
// Exports
export var nav = `hGFVDgd0mg8k1nbiVanM`;
export var navBtn = `LUQ6vz2YrvsVsJM5leU0`;
export var active = `DkkqgPiCpgzWgJJSiPiB`;
export default ___CSS_LOADER_EXPORT___;
