import { Flex, Input, message, Modal, Radio, Space } from 'antd';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { observer } from 'mobx-react';
import { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { Task } from '../../../../ types';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { $class } from '../../../../../utils';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';
import SliderButton from '../../../SliderButton';
import Labels from '../../Kanban/Distribution/Labels';
import Accordion from '../Accordion';
import ImageUpload from './ImageUpload';
import * as styles from './index.module.scss';
dayjs.extend(duration);

interface TaskItemProps extends Task {
  //onClick: (job: Task) => void;
  //isSelectedJob: boolean;
  submitBtnName?: string;
  //submitBtnTypes?: ButtonSubmitType[];
  children?: ReactNode;
  className?: string;
}
interface DataItem {
  _id: string;
  name: string;
  status: string;
}

const TaskItem: FC<TaskItemProps> = ({
  status,
  name,
  _id,
  title,
  onClick,
  description,
  assignedTo,
  comments,
  workspaceId,
  expectedDate,
  labels,
  jobId,
  targetDate,
  deliveryData,
  jobIdShort,
  dueDate,
  className,
  children,
  submitBtnName,
  isSelectedTask = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSelect, setModalOpenSelect] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [modalUrl, setModalUrl] = useState('');
  const [loading, setLoading] = useState(false);
  //const [valueRadio, setValueRadio] = useState<string | null>('completed');
  const [valueRadio, setValueRadio] = useState<string | null>(null);

  // const [value, setValue] = useState<string | null>(null);
  const [statusViev, setStatusViev] = useState<string>();
  const [nameStatus, setNameStatus] = useState<string | null>(null);

  const [isInputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { TextArea } = Input;

  const handleOk = () => {
    setIsModalOpen(false);
    setModalOpenSelect(false);
  };

  useEffect(() => {
    setSelectedTaskId(_id);
  }, []);

  const onChangeStatusHandler = async () => {
    if (!navigator?.geolocation) {
      return message.error('Авто маршрут недоступен');
    }
    setLoading(true);

    if (
      StagesStore.dataMyTaskOpened?.stageId == '6707a43f598d48bda5860bfd' ||
      StagesStore.dataMyTaskOpened?.stageId == '670cc7cd1f9ba41db1b99dd0' ||
      StagesStore.dataMyTaskOpened?.stageId == '6720e88c847708a28a16c737' ||
      StagesStore.dataMyTaskOpened?.stageId == '6720e8b1847708a28a16c739'
    ) {
      setModalOpenSelect(true);
      setLoading(false);
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          console.log(position.coords.latitude, position.coords.longitude);

          const geo = {
            latitude: `${position.coords.latitude}`,
            longitude: `${position.coords.longitude}`,
          };

          try {
            await StagesStore.updateTaskWithGeo(workspaceId, selectedTaskId, 'completed', geo);
            // if (StagesStore.taskUpdateError) {
            //   return message.error('Update Error');
            //console.log
            // }
            message.info('Success1');
            setIsModalOpen(false);
            StagesStore.setLayoutPageDelivery('library');
            // StagesStore.getMyTasks(workspaceId).catch((e) => {
            //   console.error(e);
            // });
          } catch (err) {
            if (err instanceof AxiosError && err?.response?.data?.message?.message) {
              return message.error(err?.response?.data?.message?.message);
            }

            message.error('Update Error');
            setIsModalOpen(false);
          } finally {
            setLoading(false);
          }
        },
        (err) => {
          console.error(err.message);
          message.error('Не удалось получить ваше местополжение');
          setLoading(false);
        },

        {
          enableHighAccuracy: true, // повышенная точность
          timeout: 5000, // максимальное время ожидания
          maximumAge: 0, // не использовать кешированные данные
        }
      );
    }
  };
  const radioOptions: any = {
    completed: 'Успешно',
    update: 'Проблема',
    cancel: 'Отмена',
    decline: 'Отказ от заказа',
    completedFinal: 'Успешно завершен',
  };

  /**** */

  const handleChange = (e: any) => {
    const selectedValue = e.target.value;
    const selectedText = radioOptions[selectedValue];

    setValueRadio(selectedValue);
    setNameStatus(selectedText);
    setStatusViev(selectedValue);
  };

  useEffect(() => {
    valueRadio !== null;
    setNameStatus(nameStatus);
    setStatusViev(statusViev);
    console.log('Value:', valueRadio);
    console.log('Status:', nameStatus);
  }, [valueRadio, nameStatus, statusViev]); // Добавляем nameStatus в зависимости

  const handleContinue = () => {
    // if (value === '1') {
    //   setModalOpenSelect(false);
    //   handleSend();
    // } else {
    setInputVisible(true);
    // }
  };

  const handleBack = () => {
    setInputVisible(false);
    //setValue(null);'
    setValueRadio(null);
  };

  const handleSend = async () => {
    console.log(selectedStatus, workspaceId, selectedTaskId, statusViev);
    statusViev && (await StagesStore.updateTask(workspaceId, selectedTaskId, statusViev));
    if (StagesStore.taskUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success2');
      setIsModalOpen(false);
      StagesStore.setLayoutPageDelivery('library');
      StagesStore.getMyTasks(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  const handleSend2 = async () => {
    if (!(valueRadio === 'completed' || valueRadio === 'completedFinal') && !inputValue.trim()) {
      // Проверка, что поле не пустое
      message.error('Комментарий обязателен. Пожалуйста, введите данные.');
      return; // Выход из функции, если поле пустое
    }

    const fileurl: string[] = StagesStore.dataUpdateJobFileName
      ? [StagesStore.dataUpdateJobFileName]
      : [];
    //console.log('fileurl', jobId, StagesStore?.selectedTask?.jobId, inputValue);

    await StagesStore.addJobComment(workspaceId, jobId, inputValue, fileurl);

    if (StagesStore.loadingAddCommentError) {
      message.error('Error');
    } else {
      message.info('Коммент добавлен');
      handleSend();
    }
  };

  const [isCopied, setIsCopied] = useState(false);

  const textToCopy = jobIdShort;

  const handleCopy = async () => {
    if (jobId !== undefined) {
      try {
        await navigator.clipboard.writeText(textToCopy ? textToCopy : '');
        setIsCopied(true);
        message.info('Скопировано', 2);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      console.error('Bitrix ID is undefined');
    }
  };

  const [jobData, setJobData] = useState<any>(null);

  useEffect(() => {
    if (!StagesStore?.selectedTask?.jobId || !StagesStore.isSelectedTask) {
      return;
    }
    StagesStore.getJobsId(workspaceId, StagesStore.selectedTask.jobId)
      .then((data) => {
        setJobData(data);
      })
      .catch((e) => {
        console.error(e);
      });

    console.log(jobData, 'dggg');
  }, [StagesStore.selectedTask?.jobId, StagesStore.isSelectedTask]);

  const [visible, setVisible] = useState(false);

  return (
    <>
      {!isSelectedTask ? (
        <div
          onClick={() =>
            onClick({
              status,
              name,
              title,
              jobId,
              _id,
              description,
              assignedTo,
              comments,
              jobIdShort,
              targetDate,
              labels,
              expectedDate,
              className,
              deliveryData,
              submitBtnName,
              dueDate,
            })
          }
          className={$class('taskitem')}
        >
          <div className={$class(styles.flex_bl, styles.cardGrid, className)}>
            {deliveryData && deliveryData.type === 'delivery' ? (
              <div className={styles.textGroup}>
                <p>Доставить:</p>
                <div className={$class(styles.textValue, 'line-clamp-3')}>
                  {deliveryData?.targetGeoPoint?.address}
                </div>
              </div>
            ) : (
              <></>
            )}
            {deliveryData && deliveryData.type === 'pickup' ? (
              <div className={styles.textGroup}>
                <p>Забрать:</p>
                <div className={$class(styles.textValue, 'line-clamp-3')}>
                  {deliveryData?.targetGeoPoint?.address}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className={styles.flexGroup}>
              <div className={styles.textGroup}>
                <p>Срок:</p>
                <div className={styles.textValue}>
                  {dayjs(dueDate).format('HH:mm')}
                  <span className={styles.separator}> • </span>
                  {dayjs(dueDate).format('DD / MM / YY')}
                </div>
              </div>
              <div className={styles.textGroup}>
                <p>Номер заказа:</p>
                <div className={styles.chip}>
                  #{jobIdShort} <Icon slug="arrow_vertical" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className={styles.name2}>{name}</p>
          <div className={styles.propertiesGrid}>
            <p>Номер заказа:</p>
            <div className={styles.copyGroup}>
              <div className={styles.chip}>#{jobIdShort}</div>
              <Button variant="outlined" icon="copy_icon" onClick={handleCopy} autoWidth />
            </div>
            <p>Дата и время:</p>
            <div>
              {dayjs(StagesStore?.dataJobs?.job?.targetDate).format('HH:mm')}
              <span className={styles.separator}> • </span>
              {dayjs(StagesStore?.dataJobs?.job?.targetDate).format('DD / MM / YY')}
            </div>
            <div className={styles.border}></div>
            <div className={styles.border}></div>

            <p>Подразделение:</p>
            <div className={styles.categ_text}>{StagesStore?.dataJobs?.job?.jobFor}</div>
            <div className={styles.border}></div>
            <div className={styles.border}></div>

            {StagesStore?.dataJobs?.job?.labels &&
            StagesStore?.dataJobs?.job?.labels?.length > 0 ? (
              <>
                <p className={styles.name}>Метки:</p>
                <Labels labels={StagesStore.dataJobs.job?.labels} _id={''} name={''} />
                <div className={styles.border}></div>
                <div className={styles.border}></div>
              </>
            ) : null}

            <p>Забрать:</p>
            <div>{StagesStore?.dataJobs?.job?.deliveryData?.pickupPoint?.address}</div>

            <p>Отправитель:</p>
            <div>
              {StagesStore?.dataJobs?.job?.deliveryData?.pickupPoint.contactData.map(
                (item, index) => (
                  <Fragment key={index}>
                    <p>{item.name}</p>
                    <a href={`tel:${item.phone}`}>{item.phone}</a>
                  </Fragment>
                )
              )}
            </div>

            <div className={styles.border}></div>
            <div className={styles.border}></div>
            <p>Доставить:</p>
            <div>{StagesStore?.dataJobs?.job?.deliveryData?.deliveryPoint?.address}</div>

            <p>Получатель:</p>
            <div>
              {StagesStore?.dataJobs?.job?.deliveryData?.deliveryPoint.contactData.map(
                (item, i) => (
                  <Fragment key={i}>
                    <p>{item.name}</p>
                    <a href={`tel:${item.phone}`}>{item.phone}</a>
                  </Fragment>
                )
              )}
            </div>

            <div className={styles.border}></div>
            <div className={styles.border}></div>
            <p>Способ оплаты:</p>
            <div>{StagesStore?.dataJobs?.job?.deliveryData?.paymentData?.method}</div>

            <p>Сумма:</p>
            <div>{StagesStore?.dataJobs?.job?.deliveryData?.paymentData?.amount} ₸</div>
          </div>

          {StagesStore?.dataJobs?.job?.jobData?.[0]?.text ? (
            <div className={styles.instraction_bl}>
              <span>Инструкция:</span>

              {StagesStore?.dataJobs?.job?.jobData?.[0]?.text && (
                <p>
                  {StagesStore?.dataJobs?.job?.jobData[0].text.split('\\n').map((line, i) => (
                    <Fragment key={i}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </p>
              )}
            </div>
          ) : (
            ''
          )}

          {children}

          <div className={styles.colasp_bl}>
            <Accordion comm={StagesStore?.dataJobs?.job?.comments} />
          </div>

          <SliderButton
            className={styles.slideBtn}
            name={submitBtnName || 'Начать выполнение'}
            onClick={onChangeStatusHandler}
            disabled={loading || StagesStore.taskUpdateLoading}
          />

          {loading && <div>Загрузка...</div>}
        </div>
      )}

      <Modal
        title={
          ['6707a43f598d48bda5860bfd'].includes(StagesStore.selectedTask?.stageId) ||
          ['6720e88c847708a28a16c737'].includes(StagesStore.selectedTask?.stageId)
            ? 'Принятие заказа на доставку'
            : 'Завершение заказа'
        }
        open={isModalOpenSelect}
        footer={null}
        onCancel={handleOk}
        className={styles.radio_block}
      >
        {!isInputVisible ? (
          <>
            <Space direction="vertical">
              <Radio.Group value={valueRadio} onChange={handleChange}>
                {['6707a43f598d48bda5860bfd'].includes(StagesStore.selectedTask?.stageId) ||
                ['6720e88c847708a28a16c737'].includes(StagesStore.selectedTask?.stageId) ? (
                  <Flex vertical gap={16}>
                    <Radio value="completed">Успешно</Radio>
                    <Radio value="update">Проблема</Radio>
                    <Radio value="cancel">Отмена</Radio>
                  </Flex>
                ) : (
                  <Flex vertical gap={16}>
                    <Radio value="completed">Успешно завершен</Radio>
                    <Radio value="update">Перенесен</Radio>
                    <Radio value="decline">Отказ от заказа</Radio>
                    <Radio value="cancel">Отмена заказа</Radio>
                  </Flex>
                )}
              </Radio.Group>
            </Space>

            <Button
              className={styles.block_but}
              variant="primary"
              onClick={handleContinue}
              disabled={!valueRadio}
            >
              Продолжить
            </Button>
          </>
        ) : (
          <div>
            <p>{nameStatus}, комментарий:</p>

            <ImageUpload jobId={''} workspaceId={''} image="" />

            <TextArea
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Введите данные..."
              style={{ marginBottom: '16px' }}
            />
            <Button variant="delivery_primary" onClick={handleBack}>
              Назад
            </Button>

            <Button
              className={styles.block_but}
              variant="delivery_primary_full"
              onClick={handleSend2}
            >
              Подтвердить
            </Button>
          </div>
        )}
      </Modal>

      <Modal title="" open={isModalOpen} onOk={handleOk} footer={null} onCancel={handleOk}>
        <img src={modalUrl} />
      </Modal>
    </>
  );
};

export default observer(TaskItem);
