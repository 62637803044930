// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bGuvXIRHvYOA8eDsjKsa{width:100%;background-color:#fff;border-radius:50px;display:flex;justify-content:center;gap:16px;padding:12px;margin:0 auto}.bGuvXIRHvYOA8eDsjKsa .k4N3D8iAGf3emxqHc5sN{border-radius:50px;box-sizing:border-box;font-family:"Mulish",sans-serif;color:#000;font-size:14px;display:flex;align-items:center;gap:10px;padding:8px 16px}.bGuvXIRHvYOA8eDsjKsa .k4N3D8iAGf3emxqHc5sN.B6aVlSS7h1jKRwbl2Azw{background-color:#3a6def;color:#fff;padding:8px 12px}.bGuvXIRHvYOA8eDsjKsa .k4N3D8iAGf3emxqHc5sN.B6aVlSS7h1jKRwbl2Azw .ui-icon-svg path{fill:#fff}.bGuvXIRHvYOA8eDsjKsa .k4N3D8iAGf3emxqHc5sN .ui-icon-svg svg{width:16px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/NewMobile/Nav/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,sBAAA,CACA,QAAA,CACA,YAAA,CACA,aAAA,CAEA,4CACE,kBAAA,CACA,qBAAA,CACA,+BAAA,CACA,UAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CACA,gBAAA,CAEA,iEACE,wBAAA,CACA,UAAA,CACA,gBAAA,CAMI,mFACE,SAAA,CAUJ,6DACE,UAAA","sourcesContent":[".nav{width:100%;background-color:#fff;border-radius:50px;display:flex;justify-content:center;gap:16px;padding:12px;margin:0 auto}.nav .navBtn{border-radius:50px;box-sizing:border-box;font-family:\"Mulish\",sans-serif;color:#000;font-size:14px;display:flex;align-items:center;gap:10px;padding:8px 16px}.nav .navBtn.active{background-color:#3a6def;color:#fff;padding:8px 12px}.nav .navBtn.active :global .ui-icon-svg path{fill:#fff}.nav .navBtn :global .ui-icon-svg svg{width:16px}"],"sourceRoot":""}]);
// Exports
export var nav = `bGuvXIRHvYOA8eDsjKsa`;
export var navBtn = `k4N3D8iAGf3emxqHc5sN`;
export var active = `B6aVlSS7h1jKRwbl2Azw`;
export default ___CSS_LOADER_EXPORT___;
