import { Collapse, CollapseProps, Flex } from 'antd';
import { FC } from 'react';
import { Comment } from '../../../../ types';
import CommentItem from './CommentItem';
import { UpOutlined } from '@ant-design/icons';

interface IProps {
  comments?: Comment[];
}

const CommentsBlock: FC<IProps> = ({ comments }) => {
  const expandIcon = ({ isActive }: any) => (
    <UpOutlined style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }} />
  );

  const commentsCollapseItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'КОММЕНТАРИИ',
      children: (
        <Flex vertical gap={10}>
          {comments
            ?.toSorted((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            ?.map((comment) => <CommentItem key={comment._id} {...comment} />)}
        </Flex>
      ),
    },
  ];

  return (
    <Collapse
      items={commentsCollapseItems}
      expandIcon={expandIcon}
      expandIconPosition="right"
      bordered={false}
      defaultActiveKey={[1]}
      ghost
    />
  );
};

export default CommentsBlock;
