import * as styles from './index.module.scss';
import { Avatar, Flex, message, Modal, Select } from 'antd';
import { observer } from 'mobx-react';
import { config } from '../../../../../config';
import { AuthStore } from '../../../../../DataSource/Stores/AuthStore';
import React, { useState } from 'react';
import Clickable from '../../../../ui/Clickable';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { useNavigate, useParams } from 'react-router-dom';

const ProfilePage = () => {
  const { id: workspaceId } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const user = AuthStore.currentUserData.user;

  const handleChangeStatus = async () => {
    if (!workspaceId || !status) return;
    await StagesStore.changeUserStatus(workspaceId, status);
    if (StagesStore.loadingChangeStatusError) {
      message.error('Update Error');
    } else {
      message.info('Success');
    }
  };

  return (
    <>
      <Flex vertical gap={24}>
        <div className={styles.userBlock}>
          <Flex gap={24}>
            <Avatar
              className={styles.avatar}
              size={96}
              src={`${config.EXTERNAL_ADDRESS}/image-uploader/${AuthStore.currentUserData.user.photo}`}
            >
              {user.firstname[0]}
            </Avatar>

            <Flex vertical gap={12}>
              <div>
                <p className={styles.label}>Имя</p>
                <p className={styles.value}>
                  {user.firstname} <span>({user.email})</span>
                </p>
              </div>

              <div>
                <p className={styles.label}>Телефон</p>
                <p className={styles.value}>{user.phoneNumber || '-'}</p>
              </div>
            </Flex>
          </Flex>

          <Clickable className={styles.editBtn} onClick={() => navigate('edit')}>
            Редактировать профиль
          </Clickable>
        </div>

        <Flex vertical gap={8}>
          <p>Статус</p>

          <div className={styles.statusBlock}>
            <Select
              defaultValue={status}
              onChange={(val) => setStatus(val)}
              options={[
                {
                  value: 'offline',
                  label: 'Оффлайн',
                },
                {
                  value: 'away',
                  label: 'Нет на месте',
                },
                {
                  value: 'online',
                  label: 'Онлайн',
                },
              ]}
            />
            <Clickable className={styles.saveBtn} onClick={handleChangeStatus}>
              Сохранить
            </Clickable>
          </div>
        </Flex>

        <Clickable className={styles.logoutBtn} onClick={() => setModalOpen(true)}>
          Выйти
        </Clickable>
      </Flex>

      <Modal
        open={modalOpen}
        closable={false}
        onOk={() => AuthStore.logout()}
        onCancel={() => setModalOpen(false)}
        okText="Да"
        cancelText="Нет"
      >
        <p>Вы точно хотите покинуть сайт?</p>
      </Modal>
    </>
  );
};

export default observer(ProfilePage);
