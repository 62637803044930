import * as styles from './index.module.scss';
import { observer } from 'mobx-react';
import Clickable from '../../../../ui/Clickable';
import { $class } from '../../../../../utils';
import Icon from '../../../../ui/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { useMemo } from 'react';

const Nav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navButtons = [
    { icon: 'new_order', name: 'Новые', route: 'new' },
    { icon: 'fi-rr-back-up', name: 'Обновленные', route: 'updated' },
    { icon: 'location', name: 'Маршрут', route: 'route' },
    { icon: 'user_alt', name: 'Профиль', route: 'profile' },
  ];

  const amount = useMemo(() => {
    if (location.pathname.includes('new')) return StagesStore.dataQueueList.length;
    if (location.pathname.includes('updated'))
      return StagesStore.getUpdatedJobs.data.updatedJobs.length;
    if (location.pathname.includes('route')) return StagesStore.dataMyTasks.length;
  }, [
    location.pathname,
    StagesStore.dataQueueList.length,
    StagesStore.getUpdatedJobs.data.updatedJobs.length,
    StagesStore.dataMyTasks.length,
  ]);

  const formatAmount = (amount: number) => {
    if (amount > 99) {
      return '99+';
    }
    return amount.toString();
  };

  return (
    <div className={styles.nav}>
      {navButtons.map((navButton) => (
        <Clickable
          key={navButton.name}
          className={$class(styles.navBtn, [
            styles.active,
            location.pathname.includes(navButton.route),
          ])}
          onClick={() => navigate(navButton.route)}
        >
          <Icon slug={navButton.icon} />
          {location.pathname.includes(navButton.route) && navButton.name}
          {location.pathname.includes(navButton.route) && <b>{amount && formatAmount(amount)}</b>}
        </Clickable>
      ))}
    </div>
  );
};

export default observer(Nav);
