// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gYFCQFXtc_G6wzLEXdOV{display:flex;flex-direction:column;gap:32px}.gYFCQFXtc_G6wzLEXdOV .CU0YbM9QKrdoOeF8wK16{font-weight:700}.gYFCQFXtc_G6wzLEXdOV .ui-button{margin-left:auto;background:#3a6def;border-radius:50px;padding:6px 16px;border:none;color:#fff}.gYFCQFXtc_G6wzLEXdOV .ui-button path{fill:#fff}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/NewMobile/RoutePage/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,4CACE,eAAA,CAGF,iCACE,gBAAA,CACA,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA,CAEA,sCACE,SAAA","sourcesContent":[".routePage{display:flex;flex-direction:column;gap:32px}.routePage .label{font-weight:700}.routePage :global(.ui-button){margin-left:auto;background:#3a6def;border-radius:50px;padding:6px 16px;border:none;color:#fff}.routePage :global(.ui-button) path{fill:#fff}"],"sourceRoot":""}]);
// Exports
export var routePage = `gYFCQFXtc_G6wzLEXdOV`;
export var label = `CU0YbM9QKrdoOeF8wK16`;
export default ___CSS_LOADER_EXPORT___;
