import { Upload, message, UploadFile, Image, Flex } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { config } from '../../../../../config';
import { PlusOutlined } from '@ant-design/icons';
import * as styles from './index.module.scss';
import IconButton from '../../../../ui/IconButton';
import TimerAddMinuts from '../../TimerAddMinuts';

interface IProps {
  images?: string[];
  _id: string;
  jobId: string;
  workspaceId: string;
  onLoad?: () => void;
}

const MultipleImagesUpload: FC<IProps> = ({ images, _id, jobId, workspaceId, onLoad }) => {
  const [fileList, setFileList] = useState<UploadFile[]>(
    images
      ? images.map((img, i) => ({
          uid: i.toString(),
          name: img,
          status: 'done',
          url: `${config.EXTERNAL_ADDRESS}/image-uploader/${img}`,
        }))
      : []
  );

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toast.error('Поддерживаемые форматы: JPG, PNG');
      return false;
    }
    return true;
  };

  const handleImageChange = async (file: RcFile): Promise<boolean> => {
    const formData = new FormData();
    formData.append('file', file);
    await StagesStore.addToProdImages(workspaceId, jobId, formData);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
      StagesStore.setViewButtonFinish(false);
    } else {
      message.info('Success');
      StagesStore.setViewButtonFinish(true);
      onLoad && onLoad();
      setFileList((prev) => [
        ...prev,
        {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: URL.createObjectURL(file),
        },
      ]);
    }
    return false;
  };

  const handleRemove = async (file: UploadFile) => {
    try {
      await StagesStore.deleteProdImage(workspaceId, jobId, file.name);
      setFileList((prev) => prev.filter((img) => img.name !== file.name));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Flex vertical gap={16}>
      <Flex align="center" justify="space-between" gap={8}>
        <Upload
          className={styles.upload}
          multiple
          listType="picture-card"
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={({ file }) => handleImageChange(file as RcFile)}
        >
          <PlusOutlined style={{ fontSize: '28px', color: '#F1B729' }} />
        </Upload>

        <TimerAddMinuts
          taskId={_id}
          jobId={jobId}
          exDate={StagesStore?.dataJobs?.job?.expectedDate}
          workId={workspaceId}
        />
      </Flex>

      <Flex align="center" gap={16} wrap="wrap">
        {fileList.map((file) => (
          <div key={file.uid} className={styles.imageWrap}>
            <Image
              className={styles.image}
              height={100}
              width={100}
              src={file.url}
              alt={file.name}
            />
            <IconButton
              className={styles.deleteBtn}
              icon="fi-rr-trash"
              onClick={() => handleRemove(file)}
            />
          </div>
        ))}
      </Flex>
    </Flex>
  );
};

export default MultipleImagesUpload;
