import * as styles from './index.module.scss';
import { observer } from 'mobx-react';
import { Col, Flex, Row } from 'antd';
import { FC } from 'react';
import { JobsDataType } from '../../../../ types';
import dayjs from 'dayjs';
import Icon from '../../../../ui/Icon';
import { $class } from '../../../../../utils';
import { useNavigate } from 'react-router-dom';

const JobCard: FC<JobsDataType> = ({ _id, deliveryData, targetDate, jobId, labels, jobFor }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.jobCard} onClick={() => navigate(_id)}>
      <Flex vertical gap={4}>
        <p className={styles.label}>Доставить:</p>
        <p className={styles.location}>{deliveryData?.deliveryPoint?.address}</p>
      </Flex>

      <Flex vertical gap={4}>
        <p className={styles.label}>Забрать:</p>
        <p className={styles.location}>{deliveryData?.pickupPoint?.address}</p>
      </Flex>

      <Row gutter={[8, 8]} align="middle">
        <Col span={12}>
          <Flex vertical gap={4}>
            <p className={styles.label}>Срок:</p>
            <Flex align="center" gap={4}>
              <p>{dayjs(targetDate).format('HH:mm')}</p>
              <p className={styles.separator}>•</p>
              <p>{dayjs(targetDate).format('DD / MM / YYYY')}</p>
            </Flex>
          </Flex>
        </Col>

        <Col span={12}>
          <Flex vertical gap={4}>
            <p className={styles.label}>Номер заказа:</p>
            <div className={styles.idChip}>
              #{jobId} <Icon slug="arrow_vertical" />
            </div>
          </Flex>
        </Col>
      </Row>

      {labels && !!labels.length && (
        <Flex align="center" gap={8}>
          {labels.map((label) => (
            <div className={$class(styles.labelChip, styles[label.style])}>{label.caption}</div>
          ))}
        </Flex>
      )}

      <div className={styles.organizationChip}>{jobFor}</div>
    </div>
  );
};

export default observer(JobCard);
