import { Task } from '../../../../ types';
import TaskCard from '../TaskCard';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayDifference } from '../../../../../DataSource/Utils/array';
import { observer } from 'mobx-react';
import { Flex } from 'antd';
import IconButton from '../../../../ui/IconButton';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';

interface IProps {
  items: Task[];
  sorted: UniqueIdentifier[];
  setSorted: Dispatch<SetStateAction<UniqueIdentifier[]>>;
}

const InterchangeableItemList: FC<IProps> = ({ items, sorted, setSorted }) => {
  useEffect(() => {
    localStorage.setItem('dc-active-orders', JSON.stringify(sorted));
  }, [sorted]);

  const onUp = (id: string) => {
    setSorted((sortedIds) => {
      const index = sortedIds.indexOf(id);
      if (index > 0) {
        return [
          ...sortedIds.slice(0, index - 1),
          sortedIds[index],
          sortedIds[index - 1],
          ...sortedIds.slice(index + 1),
        ];
      }
      return sortedIds;
    });
  };

  const onDown = (id: string) => {
    setSorted((sortedIds) => {
      const index = sortedIds.indexOf(id);
      if (index < sortedIds.length - 1) {
        return [
          ...sortedIds.slice(0, index),
          sortedIds[index + 1],
          sortedIds[index],
          ...sortedIds.slice(index + 2),
        ];
      }
      return sortedIds;
    });
  };

  const sortedItems = sorted
    .map((id) => items.find((item) => item._id === id))
    .filter(Boolean) as Task[];

  return sortedItems.map((task) => (
    <Flex key={task._id} align="center" gap={4}>
      <Flex vertical justify="center" gap={18}>
        <IconButton
          icon="fi fi-rr-arrow-up"
          variant="secondary"
          size="sm"
          onClick={() => onUp(task._id)}
        />
        <IconButton
          icon="fi fi-rr-arrow-down"
          variant="secondary"
          size="sm"
          onClick={() => onDown(task._id)}
        />
      </Flex>
      <TaskCard {...task} />
    </Flex>
  ));
};

export default observer(InterchangeableItemList);
