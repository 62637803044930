import * as styles from './index.module.scss';
import { observer } from 'mobx-react';
import { Flex, Form, Input, message } from 'antd';
import Clickable from '../../../../ui/Clickable';
import Icon from '../../../../ui/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUpload from './ImageUpload';
import { AuthStore } from '../../../../../DataSource/Stores/AuthStore';
import IconButton from '../../../../ui/IconButton';
import { MaskedInput } from 'antd-mask-input';
import { FieldTypetwo } from '../../Profile/EditProfile';
import { useEffect, useState } from 'react';

export type FieldType = {
  firstname: string;
  secondname: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmpassword: string;
};

const ProfileEditPage = () => {
  const { id: workspaceId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false);
  const user = AuthStore.currentUserData.user;

  useEffect(() => {
    form.setFieldsValue(AuthStore.currentUserData.user);
  }, []);

  const validateConfirmPassword = (_: any, value: string) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The two passwords do not match!'));
  };

  const onFinish = async (values: FieldTypetwo) => {
    if (values.password && values.password !== values.confirmpassword) {
      message.error('Passwords do not match');
      return;
    }

    try {
      await AuthStore.editProfile(values);
    } catch (error) {
      message.error('Error');
      return;
    }

    message.info('Success');
  };

  return (
    <Flex vertical gap={32}>
      <Clickable
        className={styles.backBtn}
        onClick={() => navigate(`/workspaces/${workspaceId}/mobile/profile`)}
      >
        <Icon slug="arrow_back" />
        Назад
      </Clickable>

      <Flex vertical align="center" gap={16}>
        <ImageUpload image={user.photo} />

        <Form
          key={Date.now()}
          form={form}
          layout="vertical"
          autoComplete="off"
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item<FieldType>
            label="Имя"
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Введите имя',
              },
              {
                message: 'Вы можете вводить только буквы',
                pattern: new RegExp(/^[A-Za-zа-яёА-ЯЁ]+$/),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Фамилия"
            name="secondname"
            rules={[
              { required: true, message: 'Введите фамилию' },
              {
                message: 'Вы можете вводить только буквы',
                pattern: new RegExp(/^[A-Za-zа-яёА-ЯЁ]+$/),
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item<FieldType> label="E-mail">
            <Flex gap={16}>
              <Form.Item name="email" noStyle>
                <Input disabled />
              </Form.Item>
              <IconButton
                className={styles.penBtn}
                icon="pen"
                variant="secondary"
                size="lg"
                onClick={() => setModalOpen(true)}
              />
            </Flex>
          </Form.Item>
          <Form.Item<FieldType>
            name="phoneNumber"
            label="Телефон"
            rules={[{ required: true, message: 'Введите телефон' }]}
          >
            <MaskedInput mask="+7 (000) 000-0000" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Пароль"
            name="password"
            rules={[{ message: 'Введите пароль' }]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Повторите пароль"
            name="confirmpassword"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Введите пароль повторно' },
              { validator: validateConfirmPassword },
            ]}
          >
            <Input type="password" />
          </Form.Item>
        </Form>

        <Clickable className={styles.saveBtn}>Сохранить</Clickable>
      </Flex>
    </Flex>
  );
};

export default observer(ProfileEditPage);
