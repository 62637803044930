import { Button, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Organization, OrganizationAddress } from '../../../ types';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import Icon from '../../../ui/Icon';
import OrganizationDrawer from './OrganizationDrawer';
import * as styles from './index.module.scss';

const columns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Адрес',
    dataIndex: 'address',
    key: 'address',
    render: (val: OrganizationAddress) => <div>{val.fullAddress}</div>,
  },
];

const OrganizationsPage = () => {
  const { id } = useParams();
  const [selected, setSelected] = useState<Partial<Organization> | null>(null);

  const { data: orgs, loading, error } = WorkspacesStore.organizations;

  useEffect(() => {
    if (!id) return;

    WorkspacesStore.organizations.call(id);
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.pageTop}>
        <p className="title_delivery">Список организаций</p>
        <Button onClick={() => setSelected({})} type="primary" className={styles.but_neword}>
          <Icon slug={'fi-rr-plus'} />
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={orgs.data}
        loading={loading}
        rowKey="_id"
        onRow={(record) => ({
          onClick: () => {
            setSelected(record);
          },
        })}
        components={{
          body: {
            wrapper: (props: any) => {
              if (error) {
                return (
                  <div>
                    <p>Error loading data: {JSON.stringify(error?.message)}</p>
                  </div>
                );
              }
              return <tbody {...props} />;
            },
          },
        }}
      />

      <OrganizationDrawer data={selected} onClose={() => setSelected(null)} />
    </div>
  );
};

export default observer(OrganizationsPage);
