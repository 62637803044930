import { Alert, Collapse, Dropdown, Image, MenuProps, message, Popconfirm, Space } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { observer } from 'mobx-react';
import { FC, Fragment, useEffect, useState } from 'react';
import { Task } from '../../../../ types';
import { config } from '../../../../../config';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { $class } from '../../../../../utils';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';
import IconButton from '../../../../ui/IconButton';
import SliderButton from '../../../SliderButton';
import * as styles from './index.module.scss';
import MultipleImagesUpload from './MultipleImagesUpload';
dayjs.extend(duration);

const TaskItem: FC<Task> = ({
  status,
  name,
  _id,
  title,
  onClick,
  description,
  assignedTo,
  comments,
  workspaceId,
  expectedDate,
  createdAt,
  jobId,
  targetDate,
  jobIdShort,
  dueDate,
  isSelectedTask = false,
}) => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedTaskId, setSelectedTaskId] = useState('');

  const [photoLoaded, setPhotoLoaded] = useState(false);

  // const onChange = (e: RadioChangeEvent) => {
  //   setSelectedStatus(e.target.value);
  //   // console.log(e.target.value, 'Dfdfdf');
  // };

  useEffect(() => {
    setSelectedTaskId(_id);
  }, []);

  const onChangeStatus = async () => {
    if (!photoLoaded) {
      message.error('Загрузите фото');
      return;
    }

    console.log(selectedStatus, workspaceId, selectedTaskId, 'completed');
    await StagesStore.updateTask(workspaceId, selectedTaskId, 'completed');
    if (StagesStore.taskUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.setLayoutPage('library');
      StagesStore.getMyTasks(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  // const handleDateChange = async (value: Dayjs | null) => {
  //   if (!value) return;
  //   const formattedDate = value.toISOString();
  //   await StagesStore.updateTaskTargetDate(workspaceId, _id, formattedDate);
  //   console.log('updateTaskTargetDate');
  //   if (StagesStore.jobUpdateError) {
  //     message.error('Update Error');
  //   } else {
  //     message.info('Success');
  //     StagesStore.setLayoutPage('library');
  //     StagesStore.getMyTasks(workspaceId).catch((e) => {
  //       console.error(e);
  //     });
  //   }
  // };

  // console.log(targetDate);

  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedBitrixId, setIsCopiedBitrixId] = useState(false);

  const textToCopy = jobIdShort;
  const bitrixid2 = StagesStore?.dataJobs?.job?.externalId?.bitrixId;
  const textToCopybitrix = bitrixid2;

  const handleCopy = async () => {
    if (jobId !== undefined) {
      try {
        await navigator.clipboard.writeText(textToCopy ? textToCopy : '');
        setIsCopied(true);
        message.info('Скопировано', 2);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      console.error('Bitrix ID is undefined');
    }
  };
  const handleCopyBitrixId = async () => {
    if (bitrixid2 !== undefined) {
      try {
        await navigator.clipboard.writeText(bitrixid2);
        setIsCopiedBitrixId(true);
        message.info('Bitrix ID скопировано', 2);
        setTimeout(() => setIsCopiedBitrixId(false), 2000);
      } catch (err) {
        console.error('Failed to copy Bitrix ID: ', err);
      }
    } else {
      console.error('Bitrix ID is undefined');
    }
  };
  const [jobData, setJobData] = useState<any>(null);

  useEffect(() => {
    if (!jobId) {
      return;
    }
    StagesStore.getJobsId(workspaceId, jobId ? jobId : '')
      .then((data) => {
        setJobData(data);
      })
      .catch((e) => {
        console.error(e);
      });

    console.log(jobData, 'dggg');
  }, [jobId]);

  const ExpandIcon = () => (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.65338 3.11655L13.8068 9.26993L15 8.07668L7.65338 0.730064L0.306758 8.07668L1.5 9.26993L7.65338 3.11655Z"
        fill="#41474E"
      />
    </svg>
  );
  const [selectedMinutes, setSelectedMinutes] = useState<number | null>(null);
  const [targetDateTimer, setTargetDateTimer] = useState<number>(dayjs(expectedDate).valueOf());
  const [visible, setVisible] = useState(false);

  // const expectjob = StagesStore?.dataJobs?.job?.expectedDate;

  // useEffect(() => {
  //   const targetDateTimer = dayjs(expDateNew ? expDateNew : expectjob).valueOf();
  //   setTargetDateTimer(targetDateTimer);
  // }, [selectedMinutes, expectjob, expDateNew]);

  // const [progress, setProgress] = useState(0);
  // const [min, setMin] = useState<string | null>(null);

  // useEffect(() => {
  //   const start = dayjs(createdAt);
  //   const end = dayjs(expDateNew ? expDateNew : expectjob);
  //   const startMs = start.valueOf();
  //   const endMs = end.valueOf();
  //   const totalTime = endMs - startMs;

  //   console.log(totalTime, 'totalTime');

  //   if (totalTime <= 0) {
  //     setProgress(100);
  //     setMin(null);
  //   } else {
  //     const currentTime = dayjs().valueOf();
  //     if (currentTime >= endMs) {
  //       setProgress(100);
  //       setMin(null);
  //     } else {
  //       const progress = ((currentTime - startMs) / totalTime) * 100;
  //       setProgress(progress);
  //       const duration = dayjs.duration(totalTime);
  //       const formattedTime = `${duration.days()} дн. ${duration.hours()} ч. ${duration.minutes()} мин.`;
  //       setMin(formattedTime);
  //     }
  //   }
  // }, [createdAt, expDateNew ? expDateNew : expectjob]);

  const topDropdownMenu: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <Popconfirm
            title="Bы действительно хотите архивировать заказ?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => changeArchived(true)}
          >
            <div>Архивировать</div>
          </Popconfirm>
        </>
      ),
    },
  ];
  const changeArchived = async (isArchived: boolean) => {
    await StagesStore.updateJobArchived(workspaceId, _id, isArchived);
    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      StagesStore.getListJobs(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };
  return (
    <>
      {isSelectedTask == false ? (
        <div
          onClick={() =>
            onClick({
              status,
              name,
              title,
              jobId,
              _id,
              description,
              assignedTo,
              comments,
              jobIdShort,
              targetDate,
              expectedDate,
            })
          }
          className={$class(styles.task_item)}
        >
          <div className={styles.flex_bl}>
            <div className={styles.flex_b}>
              <p className={styles.name}>{title}</p>
              <div className={styles.add_text}>
                <p className={styles.id}>
                  #{jobIdShort} <Icon slug="arrow_vertical" />
                </p>
                <div className={styles.dateBlock}>
                  {dayjs(dueDate).format('HH : mm')}
                  <span className={styles.separator}>•</span>
                  {dayjs(dueDate).format('DD/MM/YY')}
                </div>
              </div>
            </div>
            {/*  {progress}
          <br></br>
            {dayjs(StagesStore?.dataJobs?.job?.startDate).format('DD/MM/YY HH : mm')} <br />
            {dayjs(StagesStore?.dataJobs?.job?.expectedDate).format('DD/MM/YY HH : mm')} */}
            {/*
            {expectedDate}
            <ProgressBar
              width={`${progress}%`}
              status={progress == 100 ? 'completed' : 'in_progress'}
              targetDateTimer={targetDateTimer}
            /> */}
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.flex_block}>
            <p className={styles.name2}>{name}</p>
            <Dropdown menu={{ items: topDropdownMenu }} trigger={['click']}>
              <IconButton icon="three_dots" variant="secondary" />
            </Dropdown>
          </div>
          <div className={styles.flex_jobid}>
            <p>Job ID:</p>
            <span>
              {StagesStore?.dataJobs?.job?.jobId}
              <Button
                variant="outlined"
                icon="copy_icon"
                onClick={handleCopy}
                children={undefined}
              />
            </span>
          </div>
          <div className={styles.flex_jobid}>
            <p>Bitrix Id:</p>
            <span>
              {StagesStore?.dataJobs?.job?.externalId?.bitrixId}
              <Button
                variant="outlined"
                icon="copy_icon"
                onClick={handleCopyBitrixId}
                children={undefined}
              />
            </span>
          </div>

          <div className={styles.colasp_bl}>
            {/* <Accordion
              jobData={StagesStore?.dataJobs?.job?.jobData}
              comm={StagesStore?.dataJobs?.job?.comments}
              targetDate={StagesStore?.dataJobs?.job?.targetDate}
              task={true}
              jobId={jobId}
              workId={workspaceId}
            /> */}

            <Space direction="vertical">
              <Collapse
                expandIconPosition="end"
                expandIcon={() => <ExpandIcon />}
                collapsible="header"
                defaultActiveKey={['1']}
                items={[
                  {
                    key: '1',
                    label: 'ФОТО ГОТОВОГО ПРОДУКТА',
                    children: (
                      <MultipleImagesUpload
                        onLoad={() => setPhotoLoaded(true)}
                        images={StagesStore?.dataJobs?.job?.prodImages}
                        _id={_id}
                        jobId={jobId}
                        workspaceId={workspaceId}
                      />
                    ),
                  },
                ]}
              />
              {StagesStore?.dataJobs?.job?.jobData ? (
                <Collapse
                  expandIconPosition="end"
                  collapsible="header"
                  expandIcon={() => <ExpandIcon />}
                  defaultActiveKey={['2']}
                  items={[
                    {
                      key: '2',
                      label: 'Референс',
                      children: (
                        <>
                          <div className={styles.img_parent}>
                            {StagesStore?.dataJobs?.job?.jobData?.[0]?.photoFiles?.map(
                              (file, index) => (
                                <div key={index} className={styles.one_img}>
                                  <Image src={`${config.EXTERNAL_ADDRESS}/public/${file}`} />
                                </div>
                              )
                            )}
                          </div>
                          <p className={styles.text_jobdata}>
                            {StagesStore?.dataJobs?.job?.jobData?.[0]?.text}
                          </p>
                          {StagesStore?.dataJobs?.job?.jobData?.[0]?.bitrixOrderData && (
                            <p className={styles.text_jobdata}>
                              {StagesStore?.dataJobs?.job?.jobData?.[0]?.bitrixOrderData
                                .replace(/\[\/b\]/g, '')
                                .replace(/\[b\]/g, '')
                                .split('_')
                                .map((line, i) => (
                                  <Fragment key={i}>
                                    {line}
                                    <br />
                                  </Fragment>
                                ))}
                            </p>
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              ) : (
                ''
              )}
              <Collapse
                expandIconPosition="end"
                expandIcon={() => <ExpandIcon />}
                collapsible="header"
                defaultActiveKey={['3']}
                items={[
                  {
                    key: '3',
                    label: 'сроки',
                    children: (
                      <>
                        <div>
                          <div className={styles.flex_jobid}>
                            <p>Цель</p>
                            <div className={styles.dateBlock}>
                              {dayjs(StagesStore?.dataJobs?.job?.targetDate).format('HH : mm')}
                              <span className={styles.separator}>•</span>
                              {dayjs(StagesStore?.dataJobs?.job?.targetDate).format('DD/MM/YY')}
                            </div>
                          </div>
                          <div className={styles.flex_jobid}>
                            <p>Прогноз</p>
                            <div className={styles.dateBlock}>
                              {dayjs(
                                StagesStore.ExDate
                                  ? StagesStore.ExDate
                                  : StagesStore?.dataJobs?.job?.expectedDate
                              ).format('HH : mm')}
                              <span className={styles.separator}>•</span>
                              {dayjs(
                                StagesStore.ExDate
                                  ? StagesStore.ExDate
                                  : StagesStore?.dataJobs?.job?.expectedDate
                              ).format('DD/MM/YY')}
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
              {StagesStore?.dataJobs?.job?.comments &&
                StagesStore?.dataJobs?.job?.comments.length > 0 && (
                  <Collapse
                    expandIconPosition="end"
                    expandIcon={() => <ExpandIcon />}
                    collapsible="icon"
                    defaultActiveKey={['4']}
                    items={[
                      {
                        key: '4',
                        label: 'Комментарии',
                        children: (
                          <>
                            <div>
                              {StagesStore?.dataJobs?.job?.comments?.map((comment, index) => (
                                <div key={index}>
                                  <div className={styles.dateBlock}>
                                    {dayjs(comment.createdAt).format('HH : mm')}
                                    <span className={styles.separator}>•</span>
                                    {dayjs(comment.createdAt).format('DD/MM/YY')}
                                  </div>
                                  <p>{comment.text}</p>

                                  {comment.fileUrls.length > 0 &&
                                    comment.fileUrls?.map((phot, index) => (
                                      <Image src={`${config.EXTERNAL_ADDRESS}/public/${phot}`} />
                                      // <div key={index} className={styles.link}>
                                      //   <p className={styles.linkk}>
                                      //     <Button
                                      //       variant="text"
                                      //       icon="arrow_vertical"
                                      //       autoWidth
                                      //       onClick={() =>
                                      //         window.open(
                                      //           `${config.EXTERNAL_ADDRESS}/public/${phot}`,
                                      //           '_blank'
                                      //         )
                                      //       }
                                      //     >
                                      //       Фото
                                      //     </Button>
                                      //   </p>
                                      // </div>
                                    ))}
                                </div>
                              ))}
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                )}
            </Space>
          </div>
          {/* <Button variant="primary" className="mt-8" onClick={showModal}>
            завершить
          </Button> */}
          {StagesStore.viewButtonFinish ? (
            <SliderButton name={'Завершить'} onClick={onChangeStatus} />
          ) : (
            <Alert message="Загрузите фото" type="warning" showIcon closable />
          )}
        </div>
      )}
    </>
  );
};

export default observer(TaskItem);
