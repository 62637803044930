import * as styles from './index.module.scss';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { Task } from '../../../../ types';
import { Col, Flex, Row } from 'antd';
import dayjs from 'dayjs';
import Icon from '../../../../ui/Icon';
import { $class } from '../../../../../utils';
import { useNavigate } from 'react-router-dom';

interface IProps extends Task {
  active?: boolean;
}

const TaskCard: FC<IProps> = ({
  active = false,
  _id,
  deliveryData,
  dueDate,
  jobIdShort,
  labels,
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`${_id}${active ? '?status=active' : ''}`);
  };

  return (
    <div className={$class(styles.taskCard, [styles.active, active])} onClick={onClick}>
      <Flex vertical gap={4}>
        <p className={styles.label}>Забрать:</p>
        <p className={styles.location}>{deliveryData?.targetGeoPoint?.address}</p>
      </Flex>

      <Row gutter={[8, 8]} align="middle">
        <Col span={12}>
          <Flex vertical gap={4}>
            <p className={styles.label}>Срок:</p>
            <Flex align="center" gap={4}>
              <p>{dayjs(dueDate).format('HH:mm')}</p>
              <p className={styles.separator}>•</p>
              <p>{dayjs(dueDate).format('DD / MM / YYYY')}</p>
            </Flex>
          </Flex>
        </Col>

        <Col span={12}>
          <Flex vertical gap={4}>
            <p className={styles.label}>Номер заказа:</p>
            <div className={styles.idChip}>
              #{jobIdShort} <Icon slug="arrow_vertical" />
            </div>
          </Flex>
        </Col>
      </Row>

      {labels && !!labels.length && (
        <Flex align="center" gap={8}>
          {labels.map((label) => (
            <div className={$class(styles.labelChip, styles[label.style])}>{label.caption}</div>
          ))}
        </Flex>
      )}
    </div>
  );
};

export default observer(TaskCard);
