import { Image } from 'antd';
import { FC, Fragment } from 'react';
import { config } from '../../../../../../config';
import * as styles from './index.module.scss';

interface IProps {
  jobData?: { text: string; photoFiles: string[] }[];
}

const ReferenceBlock: FC<IProps> = ({ jobData }) => {
  return (
    <div className={styles.fieldsWrap}>
      <p className={styles.title}>ОПИСАНИЕ</p>
      <div className={styles.photosWrap}>
        {jobData?.[0]?.photoFiles?.map((url) => (
          <Image
            key={url}
            className={styles.photo}
            height={200}
            src={`${config.EXTERNAL_ADDRESS}/public/${url}`}
          />
        ))}
      </div>
      {jobData?.[0]?.text && (
        <div className={styles.additionalInfoBlock}>
          {jobData[0].text.split('\n').map((line, i) => {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const parts = line.split(urlRegex);

            return (
              <Fragment key={i}>
                {parts.map((part, index) => {
                  if (urlRegex.test(part)) {
                    return (
                      <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                        {part}
                      </a>
                    );
                  }
                  return <Fragment key={index}>{part}</Fragment>;
                })}
                <br />
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ReferenceBlock;
