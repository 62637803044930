// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qzxysu8bQoBtVkENz8t1{display:flex;align-items:center;column-gap:8px;flex-wrap:wrap}.Qzxysu8bQoBtVkENz8t1 p{font-family:"Mulish",sans-serif;border-radius:8px;font-size:10px;font-weight:700;padding:4px 6px;text-transform:uppercase;margin-bottom:4px}.tQ8QuwyVL92LLpFBCBqb{color:#fff;background:#ff1f00}.YdoyCBjRbAlJOCqIlUm_{background:#330600;color:#fff}.jbJizjuLfNyhQalM48RK{background:#44b678;color:#fff}.iuiisUHVEm1wsIzVSsAs{background:#44b678;color:#fff}.lmGpqC3hzfliKSwROiqT{color:#fff;background:#ff1f00}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/LanzhouReviews/Labels/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,cAAA,CACA,wBACE,+BAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,wBAAA,CACA,iBAAA,CAIJ,sBACE,UAAA,CACA,kBAAA,CAGF,sBACE,kBAAA,CACA,UAAA,CAEF,sBACE,kBAAA,CACA,UAAA,CAEF,sBACE,kBAAA,CACA,UAAA,CAEF,sBACE,UAAA,CACA,kBAAA","sourcesContent":[".flex_labels{display:flex;align-items:center;column-gap:8px;flex-wrap:wrap}.flex_labels p{font-family:\"Mulish\",sans-serif;border-radius:8px;font-size:10px;font-weight:700;padding:4px 6px;text-transform:uppercase;margin-bottom:4px}.serviceMinusStyle{color:#fff;background:#ff1f00}.CancelStyle{background:#330600;color:#fff}.servicePlusStyle{background:#44b678;color:#fff}.kitchenPlusStyle{background:#44b678;color:#fff}.kitchenMinusStyle{color:#fff;background:#ff1f00}"],"sourceRoot":""}]);
// Exports
export var flex_labels = `Qzxysu8bQoBtVkENz8t1`;
export var serviceMinusStyle = `tQ8QuwyVL92LLpFBCBqb`;
export var CancelStyle = `YdoyCBjRbAlJOCqIlUm_`;
export var servicePlusStyle = `jbJizjuLfNyhQalM48RK`;
export var kitchenPlusStyle = `iuiisUHVEm1wsIzVSsAs`;
export var kitchenMinusStyle = `lmGpqC3hzfliKSwROiqT`;
export default ___CSS_LOADER_EXPORT___;
