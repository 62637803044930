import { animated, useSpring } from '@react-spring/web';
import { Modal, Result, Spin } from 'antd';
import { observer } from 'mobx-react';
import { FC, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { JobsDataType } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { $class, api } from '../../../../utils';
import Button from '../../../ui/Button';
import Icon from '../../../ui/Icon';
import * as styles from '../Mobile/index.module.scss';
import JobItem from './JobItem';

interface IProps {
  workspaceId: string;
}

const JobTab: FC<IProps> = ({ workspaceId }) => {
  const [selecting, setSelecting] = useState(false);
  const [rejectedJobs, setRejectedJobs] = useState<string[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const wrapOrdRef = useRef<HTMLDivElement>(null);

  const jobClick = (job: JobsDataType) => {
    StagesStore.setShowText(true);
    StagesStore.setLayoutPageJob('jobeditor');
    StagesStore.setSelectedJob(job);
    StagesStore.setIsSelectedJob(true);
    StagesStore.setShowTextDel(false);
    StagesStore.setLayoutPageJobDel('joblay');
    StagesStore.setIsSelectedJobDel(false);
    StagesStore.setSelectedJobDel(null);
  };

  const onBackJob = () => {
    StagesStore.setShowText(false);
    StagesStore.setLayoutPageJob('joblay');
    StagesStore.setIsSelectedJob(false);
    StagesStore.setSelectedJob(null);
  };

  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: StagesStore.showText ? 1 : 0,
    },
    delay: 300, // zaderzhka text
    config: { duration: 400 }, // Длительность анимации
  });

  const onSelectJob = (job: JobsDataType) => {
    if (selectedJobs.includes(job._id)) {
      setSelectedJobs(selectedJobs.filter((id) => id !== job._id));
    } else {
      setSelectedJobs([...selectedJobs, job._id]);
    }
  };

  const onAcceptSelectedJobs = async () => {
    setLoading(true);
    try {
      const { data } = await api.post(`/workspaces/${workspaceId}/jobs/getToWorkMultiJobs`, {
        jobIds: selectedJobs,
      });

      setSelecting(false);
      setSelectedJobs([]);
      setRejectedJobs(data.rejected || []);
    } catch (e) {
      console.error(e);
      toast.error('Ошибка при принятии заказов');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={styles.selectingTop}
        style={{ display: StagesStore.layoutPageJob === 'joblay' ? 'flex' : 'none' }}
      >
        <div>
          {selecting && (
            <>
              <p>{`Выбрано: ${selectedJobs.length}`}</p>
            </>
          )}
        </div>
        <div>
          {!selecting && (
            <Button variant="delivery_primary" onClick={() => setSelecting(true)} autoWidth>
              Выбрать несколько
            </Button>
          )}
          {selecting && (
            <>
              <Button
                loading={loading}
                variant="delivery_primary"
                onClick={onAcceptSelectedJobs}
                autoWidth
              >
                Принять заказы
              </Button>
              <Button
                variant="delivery_primary"
                onClick={() => {
                  setSelecting(false);
                  setSelectedJobs([]);
                }}
                autoWidth
              >
                Отмена
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles.prompt_wrap} ref={wrapOrdRef}>
        <div className={styles.task_flex}>
          {StagesStore.layoutPageJob === 'joblay' ? (
            <div className={styles.task_child_flex}>
              {StagesStore.loadingQueueError && 'Error loading'}
              {StagesStore.loadingQueue && StagesStore.dataQueueList?.length === 0 ? (
                <Spin size="small" />
              ) : StagesStore.dataQueueList?.length === 0 ? (
                <div className={styles.no_tasks}>
                  <Result status="warning" title="Новых заявок пока нет" />
                </div>
              ) : (
                StagesStore.dataQueueList?.map((task_i) => (
                  <div
                    className={$class(
                      selecting && styles.selectingJobItem,
                      selectedJobs.includes(task_i._id) && styles.selectedJobItem
                    )}
                    onClick={() => onSelectJob(task_i)}
                    key={task_i._id}
                  >
                    <JobItem
                      _id={task_i._id}
                      name={task_i.name}
                      jobId={task_i.jobId}
                      onClick={jobClick}
                      stageId={task_i.stageId}
                      workspaceId={task_i.workspaceId}
                      bitrixId2={task_i.externalId?.bitrixId}
                      jiraId2={task_i.externalId?.jiraId}
                      jobData={task_i.jobData}
                      jobFor={task_i.jobFor}
                      targetDate={task_i.targetDate}
                      deliveryData={task_i.deliveryData}
                      comments={task_i.comments}
                      labels={task_i.labels}
                      isSelectedJob={
                        StagesStore.isJobSelected && StagesStore.selectedJob?._id === task_i._id
                      }
                      submitBtnName={'Принять заказ'}
                      createdBy={task_i.createdBy}
                    />
                  </div>
                ))
              )}
            </div>
          ) : (
            <div className={styles.task_child_flex}>
              <div>
                <div className={styles.flex_block}>
                  <animated.p className={styles.back} onClick={onBackJob} style={textAnimation}>
                    <Icon slug="arrow_back" /> Все "Новые"
                  </animated.p>
                  {/* <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: <p>текст</p>,
                      },
                      {
                        key: '2',
                        danger: true,
                        label: <p>текст</p>,
                      },
                    ],
                  }}
                >
                  <a className="button_add" onClick={(e) => e.preventDefault()}>
                    <Icon slug="icons_menu_point" />
                  </a>
                </Dropdown> */}
                </div>

                {StagesStore.selectedJob && StagesStore.layoutPageJob == 'jobeditor' && (
                  <JobItem
                    key={StagesStore.selectedJob._id}
                    targetDate={StagesStore.selectedJob.targetDate}
                    _id={StagesStore.selectedJob._id}
                    name={''}
                    jobId={StagesStore.selectedJob.jobId}
                    workspaceId={StagesStore.selectedJob.workspaceId}
                    bitrixId2={StagesStore.selectedJob.bitrixId2}
                    jiraId2={StagesStore.selectedJob.jiraId2}
                    jobData={StagesStore.selectedJob.jobData}
                    comments={StagesStore.selectedJob.comments}
                    jobFor={StagesStore.selectedJob.jobFor}
                    labels={StagesStore.selectedJob.labels}
                    deliveryData={StagesStore.selectedJob.deliveryData}
                    onClick={() => {}}
                    isSelectedJob={StagesStore.isJobSelected}
                    stageId={''}
                    submitBtnName={'Принять заказ'}
                    createdBy={StagesStore.selectedJob.createdBy}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        centered
        title="Не удалось принять заказы"
        open={!!rejectedJobs.length}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setRejectedJobs([])}
        onCancel={() => setRejectedJobs([])}
      >
        {rejectedJobs.map((jobId) => (
          <p key={jobId}>#{jobId}</p>
        ))}
      </Modal>
    </>
  );
};

export default observer(JobTab);
