import {
  Avatar,
  Button,
  Dropdown,
  Popconfirm,
  Space,
  Table,
  TableColumnsType,
  message,
} from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Member, StagesDataType } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import LayoutStore from '../../../../DataSource/Stores/LayoutStore';
import { config } from '../../../../config';
import uWS from '../../../../uWS';
import { $class } from '../../../../utils';
import Icon from '../../../ui/Icon';
import IconButton from '../../../ui/IconButton';
import * as styles from './index.module.scss';

// import login from '../../Auth/Login';
// import { IWorkspace } from '../../../DataSource/Stores/Cabinet/WorkspacesStoreActionsClass';

type WorkspaceIdParams = {
  id?: string;
};

const Members: React.FC = () => {
  // const workspaceId = config.WORKSPACE_ID;

  const { id } = useParams<WorkspaceIdParams>();
  const workspaceId = id;

  console.log(workspaceId, id, 'Fgfgfgfg');

  const [loading, setLoading] = useState(false);
  const [stages, setStages] = useState<StagesDataType[]>([]);
  // const [workspace, setWorkspace] = useState<IWorkspace | null>(null);

  useEffect(() => {
    if (!id) return;
    (async () => {
      setLoading(true);
      try {
        // if (!Object.keys(StagesStore.dataStatus)?.length) {
        //   await StagesStore.getUserStatus(id);
        // }
        const res = await WorkspacesStore.membersGet.call(id);
        WorkspacesStore.setMembers(res.data);
        console.log(res);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }

      try {
        const res = await WorkspacesStore.getJobsInWork.call(id);
        WorkspacesStore.setInWorkJobs(res.data);
        console.log(res.data, 'sfdfdff');
        console.log(res.data);
      } catch (e: any) {
        console.error(e);
        toast.error(e.message.message);
      }

      await StagesStore.getList(id);
      setStages(StagesStore.dataList);
      setLoading(false);
    })();
  }, [id]);

  // вот это как пример подписки на смену статуса операторов в ра��очем пространстве
  useEffect(() => {
    if (!id) return;
    const eventStatus = uWS.subscribe(`workspaces/${id}/userOnline`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace user online:', data.error);
        return;
      }

      console.log('Online user:', data);
      // OperatorsStore.setOnlineUser(data);
      (async () => {
        // setLoading(true);
        try {
          // await StagesStore.getUserStatus(id);
          const res = await WorkspacesStore.membersGet.call(id);
          WorkspacesStore.setMembers(res.data);
          console.log(res);
        } catch (e: any) {
          console.error(e);
          toast.error(e.message.message);
        }

        try {
          const res = await WorkspacesStore.getJobsInWork.call(id);
          WorkspacesStore.setInWorkJobs(res.data);
          console.log(res.data);
        } catch (e: any) {
          console.error(e);
          toast.error(e.message.message);
        }

        await StagesStore.getList(id);
        setStages(StagesStore.dataList);
        setLoading(false);
      })();
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${id}/userOnline`);
    };
  }, [id]);

  const handleCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      message.info('Скопировано', 2);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const deleteMember = async (userId: string) => {
    if (!id) return;
    try {
      await WorkspacesStore.memberDel.call(id, userId);
      const res = await WorkspacesStore.membersGet.call(id);
      WorkspacesStore.setMembers(res.data);
      message.success('Member удален');
    } catch (e: any) {
      console.error(e);
      message.error(e.message.message);
    }
  };

  const columns: TableColumnsType<Member> = [
    {
      title: 'Имя',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text: any, record: Member) => (
        <div className={styles.nameBlock}>
          {record.userId.photo && (
            <Avatar
              src={`${config.EXTERNAL_ADDRESS}/image-uploader/${record.userId.photo}`}
              shape="circle"
              size={40}
            />
          )}
          {!record.userId.photo && <div className={styles.photo}>{record.userId.firstname[0]}</div>}
          <div>
            <p className={styles.name}>{record.userId.firstname}</p>
            <div
              className={$class(
                styles.status,
                [styles.online, record.onlineStatus === 'online'],
                [styles.offline, record.onlineStatus === 'offline'],
                [styles.inactive, record.onlineStatus === 'inactive']
              )}
            >
              <div />
              {/*{StagesStore.dataStatus[record.userId._id.toString()]}*/}
              {record.onlineStatus}
            </div>
          </div>
        </div>
      ),
    },

    {
      title: 'Фамилия',
      dataIndex: 'secondname',
      key: 'secondname',
      render: (text: string, record: Member) => (
        <div className={styles.nameBlock}>
          <p className={styles.name}>{record.userId.secondname}</p>
        </div>
      ),
    },
    {
      title: 'E-mail',
      dataIndex: 'userId.email',
      key: 'userId.email',
      render: (text: string, record: Member) => <>{record.userId.email}</>,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Member) => (
        <div className={styles.statusBlock}>
          <div></div>
          {record.userId.status}
        </div>
      ),
    },

    // {
    //   title:
    //     id === '6707a212598d48bda5860bf0' || id === '6720e7fa847708a28a16c731'
    //       ? 'Маршрут'
    //       : 'Активные заказы',
    //   key: '1',
    //   render: (_: any, record: Member) => {
    //     if (!WorkspacesStore.inWorkJobs[record.userId._id]) return '-';
    //     const jobs = WorkspacesStore.inWorkJobs[record.userId._id];

    //     // if (!jobs) return '-';

    //     return (
    //       <Flex vertical gap={16}>
    //         {jobs.map((job) => (
    //           <div key={job._id} className={styles.activeJobBlock}>
    //             <Flex align="center" justify="space-between">
    //               <div className={styles.copyBlock}>
    //                 #{job.jobId}
    //                 <div onClick={() => handleCopy(job.jobId)}>
    //                   <Icon slug="copy" />
    //                 </div>
    //               </div>
    //               <div className={styles.status}>
    //                 <div></div>
    //                 {StagesStore.dataList.find((item) => item._id === job.stageId)?.name}
    //               </div>
    //             </Flex>
    //             <ProgressBar startDate={job.startDate} expectedDate={job.expectedDate} />
    //           </div>
    //         ))}
    //       </Flex>
    //     );
    //   },
    // },

    {
      key: '4',
      // width: '32px',
      render: (_: string, record: any) => (
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                danger: true,
                label: (
                  <Popconfirm
                    title="Bы действительно хотите удалить пользователя?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => {
                      deleteMember(record._id);
                    }}
                  >
                    <div>Удалить</div>
                  </Popconfirm>
                ),
              },
            ],
          }}
          trigger={['click']}
        >
          <IconButton icon="three_dots" variant="secondary" />
        </Dropdown>
      ),
    },
  ];

  console.log(workspaceId, ' config.WORKSPACE_ID_DELIVERY');

  return (
    <div className={styles.membersPage}>
      <div className={styles.membersPageTop}>
        <div className={styles.title}>Управление курьерами</div>

        <Button
          onClick={() => LayoutStore.openModal('workspaceAddMember', { workspaceId: id })}
          className={styles.but_neword}
        >
          <Icon slug={'fi-rr-plus'} />
        </Button>
      </div>

      <div className={styles.tabl_bl + ' tabl_bl'}>
        <Space className={styles.tabl_bl__buttons + ' tabl_bl__buttons'}>
          <p className={styles.title}>Все курьеры</p>
        </Space>
        <Table
          className={styles.table}
          columns={columns}
          dataSource={Object.values(WorkspacesStore.members)}
          loading={loading}
          pagination={{ position: ['topRight'] }}
          rowKey="_id"
          sticky={{ offsetHeader: 0 }}
        />
      </div>
    </div>
  );
};

export default observer(Members);
