import { observer } from 'mobx-react';
import { StagesStore } from '../../../DataSource/Stores/Cabinet/StagesStore';
import { $class } from '../../../utils';
import Clickable from '../Clickable';
import Icon from '../Icon';
import './index.scss';

export interface TabData {
  id: number;
  icon?: string;
  count?: string | React.ReactNode;
  title?: string | React.ReactNode;
  content: React.ReactNode;
  className?: string;
}

interface TabsProps {
  data: TabData[];
  variant?: 'ZakazBuketov' | 'DeliveryCenter';
}

const Tabs: React.FC<TabsProps> = ({ data, variant }) => {
  const handleTabClick = (index: number) => {
    StagesStore.setTabsIndex(index);
  };

  return (
    <div className="tabs-block">
      <div className={$class('tab-buttons', ['delivery-center', variant === 'DeliveryCenter'])}>
        {data.map((tab, index) => (
          <Clickable
            key={tab.id}
            className={$class(
              'tab-btn',
              ['active', index === StagesStore.tabsIndex],
              tab.className
            )}
            style={
              variant === 'ZakazBuketov' && index !== StagesStore.tabsIndex && !tab.count
                ? { padding: '9px 16px' }
                : {}
            }
            onClick={() => handleTabClick(index)}
          >
            <div className="ui-button-wrap">
              {tab.icon && <Icon slug={tab.icon} />}
              {index === StagesStore.tabsIndex && tab.title && (
                <span className="tab-title">{tab.title}</span>
              )}
              {tab.count && <b>{tab.count}</b>}
            </div>
          </Clickable>
        ))}
      </div>
      <div className="tab-content">{data[StagesStore.tabsIndex].content}</div>
    </div>
  );
};

export default observer(Tabs);
