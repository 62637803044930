import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StagesDataType } from '../../../ types';
import { AuthStore } from '../../../../DataSource/Stores/AuthStore';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import uWS from '../../../../uWS';
import Tabs, { TabData } from '../../../ui/Tabs';
import Profile from '../../DistributionCenter/Profile';
import StatusProf from '../../StatusProf';
import JobTab from './JobTab';
import TaskTab from './TaskTab';
import * as styles from './index.module.scss';

type WorkspaceIdParams = {
  id?: string;
};
const Mobile: React.FC = () => {
  const navigate = useNavigate();
  const logoutModal = () => {
    console.log('User logged out');
    AuthStore.logout();
    navigate('/');
  };
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const { id } = useParams<WorkspaceIdParams>();
  const stages = StagesStore.dataList;
  const stageNewJobs: StagesDataType | null = stages[1] || null;

  // const workspaceId = '66e170b52fc0a03d58789c24';
  const workspaceId = id;

  //polucheniye ocheredi

  useEffect(() => {
    if (!workspaceId || !stageNewJobs) return;

    StagesStore.getListJobsQueue(workspaceId, stageNewJobs._id).catch((e) => {
      console.error(e);
    });
  }, [workspaceId, stageNewJobs]);

  //polucheniye taskov
  useEffect(() => {
    workspaceId &&
      StagesStore.getMyTasks(workspaceId).catch((e) => {
        console.error(e);
      });
    // console.log('useeffect get my task');
  }, []);

  //polucheniye stage
  useEffect(() => {
    if (workspaceId) {
      StagesStore.getList(workspaceId).catch((e) => {
        console.error(e);
      });
      // console.log('useeffect get my job');
    }
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceId) return;
    const eventStatus = uWS.subscribe(
      `workspaces/${workspaceId}/changeMyTasks/${AuthStore.currentUserData.user._id}`,
      (data: any) => {
        if (data.error) {
          console.error('Error subscribing to workspace changeMyTasks:', data.error);
          return;
        }

        console.log('changeMyTasks:', data);
        StagesStore.getMyTasks(workspaceId).catch((e) => {
          console.error(e);
        });

        if (
          data.taskId &&
          StagesStore?.dataMyTaskOpened?._id &&
          data.taskId === StagesStore?.dataMyTaskOpened?._id
        ) {
          StagesStore.getMyTaskOpen(workspaceId, StagesStore?.dataMyTaskOpened?._id).catch((e) => {
            console.error(e);
          });
        }

        if (
          (data.taskId &&
            StagesStore.selectedTask &&
            data.taskId === StagesStore.selectedTask._id) ||
          (!data.taskId &&
            data.jobId &&
            StagesStore.selectedTask &&
            StagesStore.selectedTask.jobId === data.jobId &&
            data.from !== 'updateExpectedDate')
        ) {
          StagesStore.setShowTextTask(false);
          StagesStore.setSelectedTask(null);
          StagesStore.setSelectedJob(null);
          StagesStore.setLayoutPage('library');
          StagesStore.setIsSelectedTask(false);
        }
      }
    );

    return () => {
      if (eventStatus)
        uWS.unSubscribe(
          `workspaces/${workspaceId}/changeMyTasks/${AuthStore.currentUserData.user._id}`
        );
    };
  }, [workspaceId]);

  useEffect(() => {
    if (!workspaceId) return;
    const eventStatus = uWS.subscribe(`workspaces/${workspaceId}/changeJob`, (data: any) => {
      if (data.error) {
        console.error('Error subscribing to workspace changeJob:', data.error);
        return;
      }

      console.log('changeJob:', data);
      StagesStore.getListJobsQueue(workspaceId, stageNewJobs._id).catch((e) => {
        console.error(e);
      });

      if (data.jobId && StagesStore.selectedJob && data.jobId === StagesStore.selectedJob._id) {
        StagesStore.setShowText(false);
        StagesStore.setLayoutPageJob('joblay');
        StagesStore.setIsSelectedJob(false);
        StagesStore.setSelectedJob(null);
      }
    });

    return () => {
      if (eventStatus) uWS.unSubscribe(`workspaces/${workspaceId}/changeJob`);
    };
  }, []);

  const tabData: TabData[] = [
    {
      id: 1,
      icon: 'arr_right',
      count: <>{StagesStore.dataQueueList.length}</>,
      title: 'Новые',
      content: <JobTab workspaceId={workspaceId ? workspaceId : ''} />,
    },
    {
      id: 2,
      icon: 'star',
      count: <>{StagesStore.dataMyTasks.length}</>,
      title: 'В работе',
      content: <TaskTab workspaceId={workspaceId ? workspaceId : ''} />,
    },
    {
      id: 3,
      icon: 'user',
      title: 'Профиль',
      content: (
        <>
          <Profile />
          <p onClick={showModal} className={styles.exit}>
            Выйти
          </p>
        </>
      ),
    },
    {
      id: 4,
      icon: 'wifi',
      title: 'Cтатус',
      content: <StatusProf />,
    },
  ];

  return (
    <div className={styles.distributionCenterPage}>
      <div>
        <Tabs data={tabData} />
      </div>
      <Modal
        title=""
        open={open}
        closable={false}
        onOk={logoutModal}
        onCancel={hideModal}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to leave the site?</p>
      </Modal>
    </div>
  );
};

export default observer(Mobile);
