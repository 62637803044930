import * as styles from './index.module.scss';
import { observer } from 'mobx-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useRef } from 'react';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { Flex, message } from 'antd';
import Icon from '../../../../ui/Icon';
import { $class } from '../../../../../utils';
import dayjs from 'dayjs';
import Clickable from '../../../../ui/Clickable';
import IconButton from '../../../../ui/IconButton';
import { WorkspacesStore } from '../../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { SwipeableButton } from 'react-swipeable-button';
import CommentsBlock from '../CommentsBlock';

const JobPage = () => {
  const { id: workspaceId, jobId } = useParams();
  const navigate = useNavigate();
  const swipeableButtonRef = useRef<SwipeableButton | null>(null);
  const job = StagesStore.dataJobs.job;

  useEffect(() => {
    if (!workspaceId || !jobId) return;

    StagesStore.getJobsId(workspaceId, jobId).catch((e) => {
      console.error(e);
    });
  }, []);

  const copyId = async () => {
    try {
      await navigator.clipboard.writeText(job?.jobId);
      message.info('Скопировано', 2);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSwipe = async () => {
    if (!workspaceId || !jobId) return;

    swipeableButtonRef.current?.buttonReset();

    try {
      await StagesStore.jobInwork(workspaceId, jobId);
      navigate(`/workspaces/${workspaceId}/mobile/route`);
      message.info('Успешно');
    } catch (e) {
      message.error('Ошибка');
    }
  };

  return (
    <Flex vertical gap={24}>
      <BackButton />

      <Flex vertical gap={12}>
        {job?.labels && !!job.labels.length && (
          <Flex align="center" gap={8}>
            {job.labels.map((label) => (
              <div className={$class(styles.labelChip, styles[label.style])}>{label.caption}</div>
            ))}
          </Flex>
        )}

        <Flex vertical gap={4}>
          <div className={styles.field}>
            <p className={styles.label}>Номер заказа:</p>
            <Flex align="center" gap={8}>
              <div className={styles.idChip}>#{job?.jobId}</div>
              <IconButton icon="copy_icon" variant="clear" size="sm" onClick={copyId} />
            </Flex>
          </div>

          <div className={styles.field}>
            <p className={styles.label}>Дата и время:</p>
            <Flex align="center" gap={4}>
              <p className={styles.value}>{dayjs(job?.targetDate).format('HH:mm')}</p>
              <p className={styles.value}>•</p>
              <p className={styles.value}>{dayjs(job?.targetDate).format('DD / MM / YYYY')}</p>
            </Flex>
          </div>

          {job?.createdBy && WorkspacesStore.members[job.createdBy]?.userId && (
            <div className={styles.field}>
              <p className={styles.label}>Автор:</p>
              <p className={styles.value}>
                {WorkspacesStore.members[job.createdBy].userId.firstname}{' '}
                {WorkspacesStore.members[job.createdBy].userId.secondname}
              </p>
            </div>
          )}

          <div className={styles.separator}></div>

          <div className={styles.field}>
            <p className={styles.label}>Подразделение:</p>
            <p className={$class(styles.value, styles.organization)}>{job?.jobFor}</p>
          </div>

          <div className={styles.separator}></div>

          <div className={styles.field}>
            <p className={styles.label}>Забрать:</p>
            <p className={styles.value}>{job?.deliveryData?.pickupPoint?.address}</p>
          </div>

          <div className={styles.field}>
            <p className={styles.label}>Отправитель:</p>
            <p className={styles.value}>
              {job?.deliveryData?.pickupPoint?.contactData.map((item) => (
                <Fragment key={item.phone}>
                  <p className={styles.value}>{item.name}</p>
                  <a className={styles.value} href={`tel:${item.phone}`}>
                    {item.phone}
                  </a>
                </Fragment>
              ))}
            </p>
          </div>

          <div className={styles.separator}></div>

          <div className={styles.field}>
            <p className={styles.label}>Доставить:</p>
            <p className={styles.value}>{job?.deliveryData?.deliveryPoint?.address}</p>
          </div>

          <div className={styles.field}>
            <p className={styles.label}>Получатель:</p>
            <p className={styles.value}>
              {job?.deliveryData?.deliveryPoint?.contactData.map((item) => (
                <Fragment key={item.phone}>
                  <p className={styles.value}>{item.name}</p>
                  <a className={styles.value} href={`tel:${item.phone}`}>
                    {item.phone}
                  </a>
                </Fragment>
              ))}
            </p>
          </div>

          <div className={styles.separator}></div>

          <div className={styles.field}>
            <p className={styles.label}>Способ оплаты:</p>
            <p className={styles.value}>{job?.deliveryData?.paymentData?.method}</p>
          </div>

          <div className={styles.field}>
            <p className={styles.label}>Сумма:</p>
            <p className={styles.value}>{job?.deliveryData?.paymentData?.amount} ₸</p>
          </div>

          {job?.jobData?.[0]?.text && (
            <div className={styles.instructionBlock}>
              <p className={styles.label}>Инструкция:</p>
              <p className={styles.value}>
                {job?.jobData[0].text.split('\\n').map((line, i) => (
                  <Fragment key={i}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </p>
            </div>
          )}
        </Flex>

        {!!job?.comments?.length && <CommentsBlock comments={job.comments} />}
      </Flex>

      <SwipeableButton
        ref={swipeableButtonRef}
        text="Принять заказ"
        textColor="white"
        sliderColor="white"
        sliderIconColor="#3A6DEF"
        sliderTextColor="#3A6DEF"
        circle
        noAnimate
        onSuccess={handleSwipe}
      />
    </Flex>
  );
};

export default observer(JobPage);

const BackButton = () => {
  const { id: workspaceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname.includes('new'))
    return (
      <Clickable
        className={styles.backBtn}
        onClick={() => navigate(`/workspaces/${workspaceId}/mobile/new`)}
      >
        <Icon slug="arrow_back" />
        Все "Новые"
      </Clickable>
    );

  if (location.pathname.includes('updated'))
    return (
      <Clickable
        className={styles.backBtn}
        onClick={() => navigate(`/workspaces/${workspaceId}/mobile/updated`)}
      >
        <Icon slug="arrow_back" />
        Все "Обновленные"
      </Clickable>
    );
};
