import dayjs from 'dayjs';
import { FC, Fragment } from 'react';
import { Comment } from '../../../../ types';
import * as styles from './index.module.scss';

const CommentItem: FC<Comment> = ({ createdAt, text, author }) => {
  return (
    <div className={styles.comment}>
      <div className={styles.dateBlock}>
        {dayjs(createdAt).format('HH : mm')}
        <span>•</span>
        {dayjs(createdAt).format('MM / DD / YYYY')}
      </div>
      <p className={styles.text}>
        {text.split('\n').map((line, i) => (
          <Fragment key={i}>
            {line}
            {i + 1 !== text.split('\n').length && <br />}
          </Fragment>
        ))}
      </p>
      <p className={styles.author}>Автор: {author}</p>
    </div>
  );
};

export default CommentItem;
