import { UniqueIdentifier } from '@dnd-kit/core';
import { Flex, message } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Task } from '../../../../ types';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { arrayDifference } from '../../../../../DataSource/Utils/array';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';
import TaskCard from '../TaskCard';
import InterchangeableItemList from './InterchangeableItemList';
import * as styles from './index.module.scss';

const calculateSortedByTasks = (tasks: Task[]) => {
  try {
    const savedItems: string[] = JSON.parse(localStorage.getItem('dc-active-orders') || 'error');
    const orderIds = tasks.map((x) => x._id);

    const diff = arrayDifference(
      tasks.map((x) => x._id),
      savedItems
    );

    if (diff.length === 0) {
      return savedItems;
    }

    if (orderIds.length > savedItems.length) {
      return [...new Set([...diff, ...savedItems])];
    }

    return savedItems.filter((id) => orderIds.includes(id));
  } catch (e: any) {
    return tasks.map((x) => x._id);
  }
};

const checkIfInWork = (task: Task) =>
  [
    '6720e88c847708a28a16c737',
    '6720e8b1847708a28a16c739',
    '6707a43f598d48bda5860bfd',
    '670cc7cd1f9ba41db1b99dd0',
  ].includes(task.stageId);

const RoutePage = () => {
  const { id: workspaceId } = useParams();
  const [sorted, setSorted] = useState<UniqueIdentifier[]>(
    calculateSortedByTasks(StagesStore.dataMyTasks.filter((task) => !checkIfInWork(task)))
  );

  useEffect(() => {
    if (!workspaceId) return;

    StagesStore.getMyTasks(workspaceId).catch((e) => {
      console.error(e);
    });
  }, []);

  useEffect(() => {
    setSorted(
      calculateSortedByTasks(StagesStore.dataMyTasks.filter((task) => !checkIfInWork(task)))
    );
  }, [StagesStore.dataMyTasks]);

  const autoRouteForTasks = async () => {
    if (!workspaceId || !navigator?.geolocation) {
      return message.error('Авто маршрут недоступен');
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        console.log(position.coords.latitude, position.coords.longitude);
        const newOrders = await StagesStore.autoRouteForTasks(
          workspaceId,
          `${position.coords.latitude}`,
          `${position.coords.longitude}`
        );
        if (newOrders) setSorted(newOrders.filter((x) => !checkIfInWork(x)).map((x) => x._id));
      },
      (err) => {
        console.error(err.message);
        message.error('Не удалось получить ваше местополжение');
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };

  return (
    <div className={styles.routePage}>
      <Flex vertical gap={8}>
        <p className={styles.label}>Активный заказ:</p>
        {StagesStore.dataMyTasks
          .filter((task) => checkIfInWork(task))
          .map((task) => (
            <TaskCard key={task._id} active {...task} />
          ))}
      </Flex>

      <Flex vertical gap={8}>
        <Flex align="center" justify="space-between">
          <p className={styles.label}>Все заказы:</p>
          <Button
            autoWidth
            loading={StagesStore.loadingMyTasks}
            variant="primary"
            onClick={autoRouteForTasks}
          >
            <Icon slug="refresh" />
            Авто-маршрут
          </Button>
        </Flex>
        <InterchangeableItemList
          items={StagesStore.dataMyTasks.filter((task) => !checkIfInWork(task))}
          sorted={sorted}
          setSorted={setSorted}
        />
      </Flex>
    </div>
  );
};

export default observer(RoutePage);
