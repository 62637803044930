import { Flex } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { $class } from '../../../../utils';
import Clickable from '../../../ui/Clickable';
import Icon from '../../../ui/Icon';
import * as styles from './index.module.scss';

type WorkspaceIdParams = {
  id?: string;
};

const Nav = () => {
  const { id } = useParams<WorkspaceIdParams>();

  const buttons = [
    {
      icon: 'fi fi-rr-utensils',
      link: `/workspaces/${id}`,
    },
    // { icon: 'list', link: `/workspaces/${id}/orderhistory` },
    { icon: 'search', link: `/workspaces/${id}/search` },
    // { icon: 'users', link: `/workspaces/${id}/members` },
    // { icon: 'static', link: `/workspaces/${id}/statistics` },
  ];
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex vertical gap={10}>
      <div className={styles.nav}>
        {buttons.map((button, i) => (
          <Clickable
            key={i}
            className={$class(styles.navBtn, [styles.active, location.pathname === button.link])}
            onClick={() => navigate(button.link)}
          >
            <Icon slug={button.icon} />
          </Clickable>
        ))}
      </div>

      <div className={styles.nav}>
        <Clickable
          className={$class(styles.navBtn, [
            styles.active,
            location.pathname === `/workspaces/${id}/profile`,
          ])}
          onClick={() => navigate(`/workspaces/${id}/profile`)}
        >
          <Icon slug="user_circle" />
        </Clickable>
      </div>
    </Flex>
  );
};

export default Nav;
