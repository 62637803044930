import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Upload, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import * as styles from './index.module.scss';

const ImageUpload: FC = () => {
  const [img, setImg] = useState('');
  const [loading, setLoading] = useState(false);

  const getBase64 = (img: RcFile, callback: (imageUrl: string) => void): void => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toast.error('Поддерживаемые форматы: JPG, PNG');
      return false;
    }
    return true;
  };

  const handleImageChange = async (file: RcFile): Promise<boolean> => {
    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);

    await StagesStore.uploadJobImage(formData);

    getBase64(file, (url) => {
      setImg(url);
      setLoading(false);
    });

    if (StagesStore.jobUpdateError) {
      message.error('Update Error');
    }

    return false;
  };

  const uploadBtn = loading ? (
    <LoadingOutlined style={{ color: '#F1B729' }} />
  ) : (
    <PlusOutlined style={{ fontSize: '28px', color: '#F1B729' }} />
  );

  return (
    <Upload
      className={styles.upload}
      listType="picture-card"
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={({ file }) => handleImageChange(file as RcFile)}
      style={{ backgroundColor: '#F1B72914' }}
    >
      {img ? <Avatar src={img} size={140} shape="square" /> : uploadBtn}
    </Upload>
  );
};

export default ImageUpload;
