import { animated, useSpring } from '@react-spring/web';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { FC } from 'react';
import TaskItem from '..';
import { GeoPoint } from '../../../../../ types';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import Button from '../../../../../ui/Button';
import Icon from '../../../../../ui/Icon';
import * as styles from './index.module.scss';
import SelectedTaskMenu from './TaskMenu';

export interface SelectProp {
  workId?: string;
  btnName?: string;
}

const SelectedTask: FC<SelectProp> = ({ workId, btnName }) => {
  const onBack = () => {
    StagesStore.setShowTextTaskDelivery(false);
    StagesStore.setLayoutPageDelivery('library');
    StagesStore.setIsSelectedTask(false);
  };

  const handleNavigate = (geoPoint?: GeoPoint) => {
    if (!geoPoint) {
      return;
    }

    const { latitude, longitude } = geoPoint;

    const deepLink = `dgis://2gis.ru/routeSearch/to/${longitude},${latitude}/go`;

    // Открываем диплинк в новой вкладке
    const newTab = window.open(deepLink, '_blank');

    if (newTab) {
      // Закрываем вкладку через небольшой интервал
      setTimeout(() => {
        newTab.close();
      }, 3000); // Задержка в 3000 мс (3 секунды)
    } else {
      console.error('Не удалось открыть новую вкладку.');
    }
  };

  const textAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: StagesStore.showTextTaskDelivery ? 1 : 0,
    },
    delay: 500,
    config: { duration: 400 },
  });

  return (
    <div className={styles.prompts_create}>
      <div className={styles.flex_block}>
        <animated.p className={styles.back} onClick={onBack} style={textAnimation}>
          <Icon slug="arrow_back" />
          Весь маршрут
        </animated.p>

        {StagesStore.selectedTask && <SelectedTaskMenu task={StagesStore.selectedTask} />}
      </div>

      {StagesStore.selectedTask && (
        <TaskItem
          _id={StagesStore?.selectedTask?._id || ''}
          dueDate={StagesStore?.selectedTask?.dueDate || ''}
          title={StagesStore?.selectedTask?.title || ''}
          status={StagesStore?.selectedTask?.status || ''}
          description={StagesStore?.selectedTask?.description || ''}
          stageName={StagesStore?.selectedTask?.stageName || ''}
          onClick={() => {}}
          comments={StagesStore?.selectedTask?.comments || []}
          jobId={StagesStore?.selectedTask?.jobId}
          jobIdShort={StagesStore?.selectedTask?.jobIdShort}
          expectedDate={StagesStore?.selectedTask?.expectedDate}
          labels={StagesStore?.selectedTask?.labels}
          isSelectedTask={StagesStore.isSelectedTask}
          workspaceId={workId ? workId : ''}
          submitBtnName={btnName}
          children={
            <Button
              icon="location"
              className={styles.navigatorBtn}
              onClick={() => {
                console.log(toJS(StagesStore?.selectedTask));

                // pickupPoint ?? deliveryPoint
                handleNavigate(StagesStore.selectedTask?.deliveryData?.targetGeoPoint?.geoPoint);
                // const { latitude: lat1, longitude: long1 } =
                //   StagesStore.selectedJob?.deliveryData?.pickupPoint.geoPoint || {};
                // const { latitude: lat2, longitude: long2 } =
                //   StagesStore.selectedJob?.deliveryData?.deliveryPoint.geoPoint || {};
                // window.open(`https://2gis.ru/geo/${lat1},${long1}|${lat2},${long2}`);
              }}
            >
              Навигатор
            </Button>
          }
        />
      )}
    </div>
  );
};

export default observer(SelectedTask);
