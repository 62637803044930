import React, { FC } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import dayjs from 'dayjs';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '33px',
    padding: '24px 15px',
  },
  image: {
    width: 80,
  },
  rowWrap: {
    width: '100%',
    flexDirection: 'column',
    gap: 12,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  label: {
    fontSize: 12,
    fontWeight: 'thin',
  },
  value: {
    fontSize: 12,
    fontWeight: 500,
  },
});

interface IProps {
  bitrixId?: string;
  targetDate: string;
  jobData?: string;
}

const Receipt: FC<IProps> = ({ bitrixId, targetDate, jobData }) => {
  const typeMatch = jobData?.match(/Вид изделия\s([^,]+)/);
  const type = typeMatch ? typeMatch[1] : null;

  const stuffingMatch = jobData?.match(/Начинка\s([^,]+)/);
  const stuffing = stuffingMatch ? stuffingMatch[1] : null;

  return (
    <Document>
      <Page style={styles.page} size={{ height: 360, width: 240 }}>
        <Image
          style={styles.image}
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQEtXp-_1jXj-UaQ4V_kYuG4NZzER_thwcaA&s"
        />

        <View style={styles.rowWrap}>
          <View style={styles.row}>
            <Text style={styles.label}>Номер:</Text>
            <Text style={styles.value}>{bitrixId || '-'}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Дата:</Text>
            <Text style={styles.value}>{dayjs(targetDate).format('DD / MM / YYYY')}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Тип:</Text>
            <Text style={styles.value}>{type || '-'}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Начинка:</Text>
            <Text style={styles.value}>{stuffing || '-'}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Receipt;
