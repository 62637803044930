import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { ProfileStore } from '../../../../../DataSource/Stores/Site/ProfileStore';
import { config } from '../../../../../config';
import Button from '../../../../ui/Button';
import IconButton from '../../../../ui/IconButton';
import * as styles from './index.module.scss';

interface IProps {
  image?: string;
}

const ImageUpload: FC<IProps> = ({ image }) => {
  const [img, setImg] = useState(image ? `${config.EXTERNAL_ADDRESS}/image-uploader/${image}` : '');
  const [loading, setLoading] = useState(false);

  const getBase64 = (img: RcFile, callback: (imageUrl: string) => void): void => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toast.error('Поддерживаемые форматы: JPG, PNG');
      return false;
    }
    return true;
  };

  const handleImageChange = async (file: RcFile): Promise<boolean> => {
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);
    await ProfileStore.updateUserPhoto(formData);
    getBase64(file, (url) => {
      setImg(url);
      setLoading(false);
    });
    return false;
  };

  const handleImageDelete = async () => {
    await ProfileStore.deleteUserPhoto();
    setImg('');
  };

  return (
    <div className={styles.uploadWrap}>
      {!img && (
        <Upload
          listType="picture-circle"
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={({ file }) => handleImageChange(file as RcFile)}
        >
          {loading ? (
            <LoadingOutlined style={{ color: '#F1B729' }} />
          ) : (
            <PlusOutlined style={{ fontSize: '28px', color: '#F1B729' }} />
          )}
        </Upload>
      )}

      {img && (
        <>
          <Avatar src={img} size={96} shape="circle" />
          <Upload
            className={styles.upload}
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={({ file }) => handleImageChange(file as RcFile)}
          >
            <IconButton className={styles.editBtn} icon="pen_white" />
          </Upload>
          <Button className={styles.delBtn} variant="text" onClick={handleImageDelete}>
            Удалить
          </Button>
        </>
      )}
    </div>
  );
};

export default ImageUpload;
