import { Dropdown, Modal } from 'antd';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Task } from '../../../../../ types';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';
import IconButton from '../../../../../ui/IconButton';

interface IProps {
  task: Task;
}

const SelectedTaskMenu = ({ task }: IProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);

    setLoading(true);
    try {
      await StagesStore.rejectJob(task.workspaceId, task.jobId);
      await StagesStore.getMyTasks(task.workspaceId);
      StagesStore.setSelectedTask(null);
      StagesStore.setIsSelectedTask(false);
      StagesStore.setLayoutPageDelivery('library');
    } catch (e) {
      console.error(e);
      toast.error('Ошибка при отказе от заказа');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Dropdown
        placement="bottomRight"
        menu={{ items: [{ key: 1, label: 'Отказаться', onClick: showModal }] }}
        trigger={['click']}
      >
        <IconButton variant="delivery_primary" icon="fi-rr-menu-dots-vertical" />
      </Dropdown>

      <Modal
        title="Подтверждение"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Да"
        cancelText="Нет"
        centered
        okButtonProps={{ loading }}
      >
        <p>Вы уверены, что хотите отказаться?</p>
      </Modal>
    </div>
  );
};

export default SelectedTaskMenu;
