import * as styles from './index.module.scss';
import { observer } from 'mobx-react';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';
import Nav from './Nav';
import { ConfigProvider } from 'antd';
import JobPage from './JobPage';
import { useEffect } from 'react';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import NewJobsPage from './NewJobsPage';
import UpdatedJobsPage from './UpdatedJobsPage';
import RoutePage from './RoutePage';
import TaskPage from './TaskPage';
import ProfilePage from './ProfilePage';
import ProfileEditPage from './ProfileEditPage';
import uWS from '../../../../uWS';
import { AuthStore } from '../../../../DataSource/Stores/AuthStore';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';

const NewMobile = () => {
  const { id: workspaceId } = useParams();

  useEffect(() => {
    (async () => {
      if (!workspaceId) return;
      try {
        await WorkspacesStore.membersGet.call(workspaceId);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (!workspaceId) return;
    const eventStatus = uWS.subscribe(
      `workspaces/${workspaceId}/changeMyTasks/${AuthStore.currentUserData.user._id}`,
      (data: any) => {
        console.log('changeMyTasks:', data);

        if (data.error) {
          console.error('Error subscribing to workspace changeMyTasks:', data.error);
          return;
        }

        StagesStore.getMyTasks(workspaceId).catch((e) => {
          console.error(e);
        });

        if (
          data.taskId &&
          StagesStore?.dataMyTaskOpened?._id &&
          data.taskId === StagesStore?.dataMyTaskOpened?._id
        ) {
          StagesStore.getMyTaskOpen(workspaceId, StagesStore?.dataMyTaskOpened?._id).catch((e) => {
            console.error(e);
          });
        }

        if (
          (data.taskId &&
            StagesStore.selectedTask &&
            data.taskId === StagesStore.selectedTask._id) ||
          (!data.taskId &&
            data.jobId &&
            StagesStore.selectedTask &&
            StagesStore.selectedTask.jobId === data.jobId &&
            data.from !== 'updateExpectedDate')
        ) {
          StagesStore.setShowTextTask(false);
          StagesStore.setSelectedTask(null);
          StagesStore.setSelectedJob(null);
          StagesStore.setIsSelectedTask(false);
        }
      }
    );

    return () => {
      if (eventStatus)
        uWS.unSubscribe(
          `workspaces/${workspaceId}/changeMyTasks/${AuthStore.currentUserData.user._id}`
        );
    };
  }, [workspaceId]);

  return (
    <Routes>
      <Route Component={Layout}>
        <Route path="/new" element={<NewJobsPage />} />
        <Route path="/new/:jobId" element={<JobPage />} />
        <Route path="/updated" element={<UpdatedJobsPage />} />
        <Route path="/updated/:jobId" element={<JobPage />} />
        <Route path="/route" element={<RoutePage />} />
        <Route path="/route/:taskId" element={<TaskPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/profile/edit" element={<ProfileEditPage />} />
        <Route path="*" element={<Navigate to="new" />} />
      </Route>
    </Routes>
  );
};

export default observer(NewMobile);

const Layout = () => {
  return (
    <div className={styles.layout}>
      <Nav />
      <div className={styles.children}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Mulish',
            },
          }}
        >
          <Outlet />
        </ConfigProvider>
      </div>
    </div>
  );
};
