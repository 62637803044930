import { Dropdown, Modal } from 'antd';
import IconButton from '../../../../ui/IconButton';
import { FC, useState } from 'react';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import toast from 'react-hot-toast';
import { Task } from '../../../../ types';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

const ActionButton: FC<Task> = ({ workspaceId, jobId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleOk = async () => {
    setModalOpen(false);
    setLoading(true);

    try {
      await StagesStore.rejectJob(workspaceId, jobId);
      await StagesStore.getMyTasks(workspaceId);
      StagesStore.setSelectedTask(null);
      StagesStore.setIsSelectedTask(false);
      navigate(`/workspaces/${workspaceId}/mobile/route`);
    } catch (e) {
      console.error(e);
      toast.error('Ошибка при отказе от заказа');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dropdown
        placement="bottomRight"
        menu={{ items: [{ key: 1, label: 'Отказаться', onClick: () => setModalOpen(true) }] }}
        trigger={['click']}
      >
        <IconButton variant="secondary" icon="fi-rr-menu-dots-vertical" />
      </Dropdown>

      <Modal
        title="Подтверждение"
        open={modalOpen}
        onOk={handleOk}
        onCancel={() => setModalOpen(false)}
        okText="Да"
        cancelText="Нет"
        centered
        okButtonProps={{ loading }}
      >
        <p>Вы уверены, что хотите отказаться?</p>
      </Modal>
    </>
  );
};

export default observer(ActionButton);
