import * as styles from './index.module.scss';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { Flex, Input, message, Modal, Radio } from 'antd';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Clickable from '../../../../ui/Clickable';
import ImageUpload from './ImageUpload';
import { Task } from '../../../../ types';
import { useNavigate } from 'react-router-dom';

interface IProps extends Task {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const FinishModal: FC<IProps> = ({ workspaceId, jobId, _id, open, setOpen }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState<0 | 1>(0);
  const [status, setStatus] = useState<string | null>(null);
  const [comment, setComment] = useState('');
  const { TextArea } = Input;

  useEffect(() => {
    setStep(0);
    setStatus(null);
    setComment('');
  }, [open]);

  const handleConfirm = async () => {
    const fileUrls: string[] = StagesStore.dataUpdateJobFileName
      ? [StagesStore.dataUpdateJobFileName]
      : [];

    await StagesStore.addJobComment(workspaceId, jobId, comment, fileUrls);

    if (StagesStore.loadingAddCommentError) {
      message.error('Error');
      return;
    }

    message.info('Комментарий добавлен');
    status && (await StagesStore.updateTask(workspaceId, _id, status));
    if (StagesStore.taskUpdateError) {
      message.error('Update Error');
    } else {
      message.info('Success');
      setOpen(false);
      navigate(`/workspaces/${workspaceId}/mobile/route`);
      StagesStore.getMyTasks(workspaceId).catch((e) => {
        console.error(e);
      });
    }
  };

  return (
    <Modal
      title={
        ['6707a43f598d48bda5860bfd', '6720e88c847708a28a16c737'].includes(
          StagesStore.selectedTask?.stageId
        )
          ? 'Принятие заказа на доставку'
          : 'Завершение заказа'
      }
      open={open}
      footer={null}
      onCancel={() => setOpen(false)}
    >
      {step === 0 && (
        <Flex vertical gap={32}>
          {['6707a43f598d48bda5860bfd', '6720e88c847708a28a16c737'].includes(
            StagesStore.selectedTask?.stageId
          ) && (
            <Radio.Group value={status} onChange={(e) => setStatus(e.target.value)}>
              <Flex vertical gap={16}>
                <Radio value="completed">Успешно</Radio>
                <Radio value="update">Проблема</Radio>
                <Radio value="cancel">Отмена</Radio>
              </Flex>
            </Radio.Group>
          )}

          {!['6707a43f598d48bda5860bfd', '6720e88c847708a28a16c737'].includes(
            StagesStore.selectedTask?.stageId
          ) && (
            <Radio.Group value={status} onChange={(e) => setStatus(e.target.value)}>
              <Flex vertical gap={16}>
                <Radio value="completed">Успешно завершен</Radio>
                <Radio value="update">Перенесен</Radio>
                <Radio value="decline">Отказ от заказа</Radio>
                <Radio value="cancel">Отмена заказа</Radio>
              </Flex>
            </Radio.Group>
          )}

          {!!status && (
            <Clickable className={styles.saveBtn} onClick={() => setStep(1)}>
              Сохранить
            </Clickable>
          )}
        </Flex>
      )}

      {step === 1 && (
        <Flex vertical align="center" gap={16}>
          <p>Комментарий:</p>

          <ImageUpload />

          <TextArea
            placeholder="Введите комментарий..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

          <Flex vertical gap={8} style={{ width: '100%' }}>
            <Clickable className={styles.saveBtn} onClick={() => setStep(0)}>
              Назад
            </Clickable>
            <Clickable className={styles.saveBtn} onClick={handleConfirm}>
              Подтвердить
            </Clickable>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default observer(FinishModal);
