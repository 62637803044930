import React, { FC } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { StagesStore } from '../../../../../../DataSource/Stores/Cabinet/StagesStore';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '38px',
    padding: '24px 15px',
  },
  image: {
    width: 80,
  },
  id: {
    fontSize: 18,
    fontWeight: 'medium',
  },
  rowWrap: {
    width: '100%',
    flexDirection: 'column',
    gap: 12,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  labelWrap: {
    flexDirection: 'column',
  },
  label: {
    fontSize: 12,
    fontWeight: 'thin',
  },
  value: {
    fontSize: 12,
    fontWeight: 500,
  },
});

interface IProps {
  jobData?: string;
}

const Receipt2: FC<IProps> = ({ jobData }) => {
  const dueDate = StagesStore.dataSystemTask[0].dueDate;

  const stuffingMatch = jobData?.match(/Начинка\s([^,]+)/);
  const stuffing = stuffingMatch ? stuffingMatch[1] : null;

  return (
    <Document>
      <Page style={styles.page} size={{ height: 360, width: 240 }}>
        <Image
          style={styles.image}
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQEtXp-_1jXj-UaQ4V_kYuG4NZzER_thwcaA&s"
        />

        <View style={styles.rowWrap}>
          <View style={styles.row}>
            <View style={styles.labelWrap}>
              <Text style={styles.label}>Дата производства /</Text>
              <Text style={styles.label}>Дайындалған күні:</Text>
            </View>
            <Text style={styles.value}>{dayjs(dueDate).format('DD / MM / YYYY')}</Text>
          </View>
          <View style={styles.row}>
            <View style={styles.labelWrap}>
              <Text style={styles.label}>Годен до /</Text>
              <Text style={styles.label}>Дейін жарамды:</Text>
            </View>
            <Text style={styles.value}>
              {dayjs(dueDate).add(2, 'day').format('DD / MM / YYYY')}
            </Text>
          </View>
          <View style={styles.row}>
            <View style={styles.labelWrap}>
              <Text style={styles.label}>Начинка /</Text>
              <Text style={styles.label}>Толтыру:</Text>
            </View>
            <Text style={styles.value}>{stuffing || '-'}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Receipt2;
