import {
  Button,
  DatePicker,
  Divider,
  Flex,
  Input,
  Radio,
  Select,
  Table,
  TableColumnsType,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JobFor, JobsDataType, Member } from '../../../ types';
import { StagesStore } from '../../../../DataSource/Stores/Cabinet/StagesStore';
import { WorkspacesStore } from '../../../../DataSource/Stores/Cabinet/WorkspacesStore';
import { ManagerStore } from '../../../../DataSource/Stores/ManagerStore';
import { jobSorterByDate } from '../../../../DataSource/Utils/date';
import { pluralize } from '../../../../utils';
import Icon from '../../../ui/Icon';
import JobDrawer from '../../DeliveryCenter/Kanban/JobColumnItem/JobDrawer';
import ModalAdd from '../../DeliveryCenter/Kanban/ManagingOrder/Modal/Add';
import ModalDeliv from '../Search/ModalDeliv';
import * as styles from './index.module.scss';
import { renderCell } from './renderCell';

const { Search } = Input;
const { RangePicker } = DatePicker;

// const organizationList = ['', 'sau', 'lulu', 'metabody'];

interface JournalFilter {
  search: string;
  stage: string;
  date: [Dayjs, Dayjs] | null;
}

export interface JournalDataType extends JobsDataType {
  member: Member | null;
}

const Journal = () => {
  const { id, job_for } = useParams();

  const UNASSIGNED_ID =
    id === '6707a212598d48bda5860bf0'
      ? '6707a3ba598d48bda5860bf9'
      : id === '6720e7fa847708a28a16c731'
        ? '6720e844847708a28a16c733'
        : 'undefined';

  const organizationList =
    id === '6707a212598d48bda5860bf0'
      ? ['', 'sau', 'lulu', 'luluopt', 'metabody']
      : id === '6720e7fa847708a28a16c731'
        ? ['metafarm']
        : [];

  const [organization, setOrganization] = useState<string>(job_for || '');
  const [filter, setFilter] = useState<JournalFilter>({ search: '', stage: '', date: null });
  const [selectedJobs, setSelectedJobs] = useState<React.Key[]>([]);
  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
  const [editedJob, setEditedJob] = useState<JobsDataType | null>(null);
  const navigate = useNavigate();
  const members = WorkspacesStore.members;

  useEffect(() => {
    if (job_for && !organizationList.includes(job_for)) {
      setOrganization('');
      navigate(`/workspaces/${id}/journal`);
    } else {
      setOrganization(job_for || '');
    }

    console.log('job_for', job_for);
  }, [job_for]);

  useEffect(() => {
    if (!id) return;
    StagesStore.getListJobs(id);
    StagesStore.getList(id);
    WorkspacesStore.membersGet.call(id);
  }, [id]);

  const onChangeJobFor = (e: any) => {
    setOrganization(e.target.value);
    navigate(`/workspaces/${id}/journal/${e.target.value}`);
  };

  const onChangeFilter = (value: any, key: string) => {
    setFilter({ ...filter, [key]: value });
  };

  const onQuickFilter = (type: string) => {
    if (type === 'today+unassigned') {
      setFilter({
        ...filter,
        date: [dayjs(), dayjs()],
        stage: UNASSIGNED_ID,
      });
    }
  };

  const onEditJob = useCallback(
    (job: JobsDataType) => {
      setEditedJob(job);
      StagesStore.getJobsId(id ? id : '', job._id).catch((e) => {
        console.error(e);
      });
    },
    [id]
  );

  const dataSource = useMemo(() => {
    let allJobs = StagesStore.dataJobsList.map((job) => ({
      ...job,
      member: job.assignedTo ? members[job.assignedTo] : null,
    }));

    if (organization) allJobs = allJobs.filter((job) => job.jobFor === organization);
    if (filter.search)
      allJobs = allJobs.filter((job) => job.jobId.toString().includes(filter.search));
    if (filter.stage) allJobs = allJobs.filter((job) => job.stageId === filter.stage);
    if (filter.date) {
      const [startDate, endDate] = filter.date;

      allJobs = allJobs.filter((job) => {
        const targetDate = dayjs(job.targetDate);
        return (
          targetDate.isSame(startDate, 'day') ||
          targetDate.isSame(endDate, 'day') ||
          (targetDate.isAfter(startDate, 'day') && targetDate.isBefore(endDate, 'day'))
        );
      });
    }

    return allJobs;
  }, [filter, organization, StagesStore.dataJobsList, members]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Job ID',
        dataIndex: 'jobId',
        width: 100,
        key: 'jobId',
        render: (...props) => renderCell(...props, onEditJob),
      },
      {
        title: 'Наименование',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Этап',
        dataIndex: 'stageId',
        key: 'stageId',
        render: renderCell,
      },
      {
        title: 'JobFor',
        dataIndex: 'jobFor',
        key: 'jobFor',
        render: renderCell,
      },
      {
        title: 'Создан',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: renderCell,
        sorter: (a, b) => jobSorterByDate(a, b, 'createdAt'),
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Целевая дата',
        dataIndex: 'targetDate',
        key: 'targetDate',
        render: renderCell,
        sorter: (a, b) => jobSorterByDate(a, b, 'targetDate'),
      },
      {
        title: 'Исполнитель',
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        render: renderCell,
      },
      {
        title: 'Автор',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: renderCell,
      },
    ] as TableColumnsType<JournalDataType>;
  }, []);

  return (
    <div className={styles.journalPage}>
      <div className={styles.journalPageTop}>
        <p className="title_delivery">Журнал {organization.toLocaleUpperCase()}</p>

        {id === '6707a212598d48bda5860bf0' && organization === '' && (
          <Radio.Group onChange={onChangeJobFor} value={organization}>
            {organizationList.map((org) => (
              <Radio.Button key={org} value={org}>
                {org.toUpperCase() || 'Все'}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}

        <Button
          onClick={() => {
            ManagerStore.ModalOpenOrder = true;
            StagesStore.modalEditFormOrder = false;
          }}
          className={styles.but_neword}
        >
          <Icon slug={'fi-rr-plus'} />
        </Button>
      </div>

      <div className={styles.journalPageFilter}>
        <Flex gap={8} align="end">
          <div className={styles.inputBlock}>
            <p>Фильтр:</p>
            <Select
              placeholder="Выберите этап"
              value={filter.stage}
              options={[
                { value: '', label: 'Все' },
                { value: UNASSIGNED_ID, label: 'Нераспределено' },
              ]}
              onChange={(v) => onChangeFilter(v, 'stage')}
              style={{ width: 200 }}
            />
          </div>

          <div className={styles.inputBlock}>
            <p>Дата:</p>
            <RangePicker
              value={filter.date}
              placeholder={['С', 'По']}
              onChange={(dates) => onChangeFilter(dates, 'date')}
            />
          </div>

          <Divider type="vertical" style={{ height: 30 }} />

          <Button onClick={() => onQuickFilter('today+unassigned')} shape="round" type="dashed">
            Нераспределенные на сегодня
          </Button>
        </Flex>

        <Search
          size="large"
          placeholder="Поиск по ID"
          onSearch={(v) => onChangeFilter(v, 'search')}
          style={{ width: 300 }}
          allowClear
        />
      </div>

      <div className={styles.journalPageWrap}>
        <div className={styles.journalPageWrapSelects}>
          <span>{`Выбрано ${pluralize(selectedJobs.length, ['строка', 'строки', 'строк'])}`}</span>

          <Button
            onClick={() => setOpenDeliveryModal(true)}
            icon={<Icon slug="fi-rr-truck-arrow-right" />}
            disabled={!selectedJobs.length}
          >
            Передать на доставку
          </Button>
        </div>

        <Table<JournalDataType>
          className={styles.journalPageWrap}
          rowSelection={{
            selectedRowKeys: selectedJobs,
            onChange: (v) => setSelectedJobs(v),
            getCheckboxProps: (record: JournalDataType) => ({
              disabled: record.stageId !== UNASSIGNED_ID,
            }),
          }}
          columns={columns}
          dataSource={dataSource}
          pagination={{ position: ['topRight'] }}
          rowKey="_id"
          sticky={{ offsetHeader: 0 }}
        />
      </div>

      {editedJob && (
        <JobDrawer
          {...editedJob}
          _id={editedJob._id}
          workspaceId={id}
          isOpen={!!editedJob}
          onClose={() => setEditedJob(null)}
        />
      )}

      <ModalDeliv
        id={id}
        jobIds={selectedJobs}
        columns={columns}
        open={openDeliveryModal}
        handleModalCancel={() => setOpenDeliveryModal(false)}
        selectedRows={dataSource.filter((job) => selectedJobs.includes(job._id))}
        setSelectedRows={setSelectedJobs}
      />

      <ModalAdd _jobFor={organization as JobFor} />
    </div>
  );
};

export default observer(Journal);
