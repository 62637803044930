// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VhtvqlF6_mKYulTVwxJ5{background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;display:flex;flex-direction:column;gap:4px;padding:8px 16px}.VhtvqlF6_mKYulTVwxJ5 .SQJkxb0Dxd2jT1SNBzCd{color:#979797;display:flex;align-items:center;gap:8px}.VhtvqlF6_mKYulTVwxJ5 .K0yJHhhEZM91cvwhF0DN{color:#41474e}.VhtvqlF6_mKYulTVwxJ5 ._TZ8rmrHDWWBe2bEn9vW{display:flex;align-items:center;gap:16px}.VhtvqlF6_mKYulTVwxJ5 .v4C8dI88RWAzsDbOJsUj{color:#979797}.ant-collapse-header-text{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/components/Cabinet/DeliveryCenter/NewMobile/CommentsBlock/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CACA,gBAAA,CAEA,4CACE,aAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,4CACE,aAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,4CACE,aAAA,CAIJ,0BACE,cAAA","sourcesContent":[".comment{background-color:#fff;border:1px solid #e0e0e0;border-radius:8px;display:flex;flex-direction:column;gap:4px;padding:8px 16px}.comment .dateBlock{color:#979797;display:flex;align-items:center;gap:8px}.comment .text{color:#41474e}.comment .photosWrap{display:flex;align-items:center;gap:16px}.comment .author{color:#979797}:global(.ant-collapse-header-text){font-size:14px}"],"sourceRoot":""}]);
// Exports
export var comment = `VhtvqlF6_mKYulTVwxJ5`;
export var dateBlock = `SQJkxb0Dxd2jT1SNBzCd`;
export var text = `K0yJHhhEZM91cvwhF0DN`;
export var photosWrap = `_TZ8rmrHDWWBe2bEn9vW`;
export var author = `v4C8dI88RWAzsDbOJsUj`;
export default ___CSS_LOADER_EXPORT___;
