import * as styles from './index.module.scss';
import { observer } from 'mobx-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useRef, useState } from 'react';
import { StagesStore } from '../../../../../DataSource/Stores/Cabinet/StagesStore';
import { Flex, message } from 'antd';
import Icon from '../../../../ui/Icon';
import { $class } from '../../../../../utils';
import dayjs from 'dayjs';
import Clickable from '../../../../ui/Clickable';
import IconButton from '../../../../ui/IconButton';
import { SwipeableButton } from 'react-swipeable-button';
import CommentsBlock from '../CommentsBlock';
import { GeoPoint } from '../../../../ types';
import ActionButton from './ActionButton';
import FinishModal from './FinishModal';
import { AxiosError } from 'axios';

const TaskPage = () => {
  const { id: workspaceId, taskId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const swipeableButtonRef = useRef<SwipeableButton | null>(null);
  const task = StagesStore.dataMyTaskOpened;
  const job = StagesStore.dataJobs.job;
  const active = new URLSearchParams(search).get('status') === 'active';

  useEffect(() => {
    if (!workspaceId || !taskId) return;

    StagesStore.getMyTaskOpen(workspaceId, taskId)
      .then(() => {
        StagesStore.setSelectedTask(StagesStore.dataMyTaskOpened);
        StagesStore.setIsSelectedTask(true);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (!workspaceId || !StagesStore?.selectedTask?.jobId || !StagesStore.isSelectedTask) return;

    StagesStore.getJobsId(workspaceId, StagesStore.selectedTask.jobId).catch((e) => {
      console.error(e);
    });
  }, [StagesStore.selectedTask?.jobId, StagesStore.isSelectedTask]);

  const copyId = async () => {
    if (!task?.jobId) return;

    try {
      await navigator.clipboard.writeText(task.jobId);
      message.info('Скопировано', 2);
    } catch (e) {
      console.error(e);
    }
  };

  const goBack = () => navigate(`/workspaces/${workspaceId}/mobile/route`);

  const handleNavigate = (geoPoint?: GeoPoint) => {
    if (!geoPoint) return;

    const { latitude, longitude } = geoPoint;

    const deepLink = `dgis://2gis.ru/routeSearch/to/${longitude},${latitude}/go`;

    const newTab = window.open(deepLink, '_blank');

    if (newTab) {
      setTimeout(() => {
        newTab.close();
      }, 3000);
    } else {
      console.error('Не удалось открыть новую вкладку');
    }
  };

  const handleSwipe = async () => {
    if (!workspaceId || !task?._id) return;

    swipeableButtonRef.current?.buttonReset();

    if (active) {
      setModalOpen(true);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        console.log(position.coords.latitude, position.coords.longitude);

        const geo = {
          latitude: `${position.coords.latitude}`,
          longitude: `${position.coords.longitude}`,
        };

        try {
          await StagesStore.updateTaskWithGeo(workspaceId, task._id, 'completed', geo);
          message.info('Success');
          goBack();
        } catch (e) {
          if (e instanceof AxiosError && e?.response?.data?.message?.message) {
            return message.error(e?.response?.data?.message?.message);
          }

          message.error('Update Error');
        }
      },

      (err) => {
        console.error(err.message);
        message.error('Не удалось получить ваше местополжение');
      },

      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };

  return (
    <>
      <Flex vertical gap={24}>
        <Flex align="center" justify="space-between">
          <Clickable className={styles.backBtn} onClick={goBack}>
            <Icon slug="arrow_back" />
            Весь маршрут
          </Clickable>

          {task && <ActionButton {...task} />}
        </Flex>

        <Flex vertical gap={12}>
          {task?.labels && !!task.labels.length && (
            <Flex align="center" gap={8}>
              {task.labels.map((label) => (
                <div className={$class(styles.labelChip, styles[label.style])}>{label.caption}</div>
              ))}
            </Flex>
          )}

          <Flex vertical gap={4}>
            <div className={styles.field}>
              <p className={styles.label}>Номер заказа:</p>
              <Flex align="center" gap={8}>
                <div className={styles.idChip}>#{task?.jobIdShort}</div>
                <IconButton icon="copy_icon" variant="clear" size="sm" onClick={copyId} />
              </Flex>
            </div>

            <div className={styles.field}>
              <p className={styles.label}>Дата и время:</p>
              <Flex align="center" gap={4}>
                <p className={styles.value}>{dayjs(task?.targetDate).format('HH:mm')}</p>
                <p className={styles.value}>•</p>
                <p className={styles.value}>{dayjs(task?.targetDate).format('DD / MM / YYYY')}</p>
              </Flex>
            </div>

            <div className={styles.separator}></div>

            <div className={styles.field}>
              <p className={styles.label}>Подразделение:</p>
              <p className={$class(styles.value, styles.organization)}>{job?.jobFor}</p>
            </div>

            <div className={styles.separator}></div>

            <div className={styles.field}>
              <p className={styles.label}>Забрать:</p>
              <p className={styles.value}>{job?.deliveryData?.pickupPoint?.address}</p>
            </div>

            <div className={styles.field}>
              <p className={styles.label}>Отправитель:</p>
              <p className={styles.value}>
                {job?.deliveryData?.pickupPoint?.contactData.map((item) => (
                  <Fragment key={item.phone}>
                    <p className={styles.value}>{item.name}</p>
                    <a className={styles.value} href={`tel:${item.phone}`}>
                      {item.phone}
                    </a>
                  </Fragment>
                ))}
              </p>
            </div>

            <div className={styles.separator}></div>

            <div className={styles.field}>
              <p className={styles.label}>Доставить:</p>
              <p className={styles.value}>{job?.deliveryData?.deliveryPoint?.address}</p>
            </div>

            <div className={styles.field}>
              <p className={styles.label}>Получатель:</p>
              <p className={styles.value}>
                {job?.deliveryData?.deliveryPoint?.contactData.map((item) => (
                  <Fragment key={item.phone}>
                    <p className={styles.value}>{item.name}</p>
                    <a className={styles.value} href={`tel:${item.phone}`}>
                      {item.phone}
                    </a>
                  </Fragment>
                ))}
              </p>
            </div>

            <div className={styles.separator}></div>

            <div className={styles.field}>
              <p className={styles.label}>Способ оплаты:</p>
              <p className={styles.value}>{job?.deliveryData?.paymentData?.method}</p>
            </div>

            <div className={styles.field}>
              <p className={styles.label}>Сумма:</p>
              <p className={styles.value}>{job?.deliveryData?.paymentData?.amount} ₸</p>
            </div>

            {job?.jobData?.[0]?.text && (
              <div className={styles.instructionBlock}>
                <p className={styles.label}>Инструкция:</p>
                <p className={styles.value}>
                  {job?.jobData[0].text.split('\\n').map((line, i) => (
                    <Fragment key={i}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </p>
              </div>
            )}
          </Flex>

          <Clickable
            className={styles.navigatorBtn}
            onClick={() => handleNavigate(task?.deliveryData?.targetGeoPoint?.geoPoint)}
          >
            <Icon slug="location" />
            Навигатор
          </Clickable>

          {!!job?.comments?.length && <CommentsBlock comments={job.comments} />}
        </Flex>

        <SwipeableButton
          ref={swipeableButtonRef}
          text={active ? 'Забрать заказ' : 'Начать выполнение'}
          textColor="white"
          sliderColor="white"
          sliderIconColor="#3A6DEF"
          sliderTextColor="#3A6DEF"
          circle
          noAnimate
          onSuccess={handleSwipe}
        />
      </Flex>

      {task && <FinishModal {...task} open={modalOpen} setOpen={setModalOpen} />}
    </>
  );
};

export default observer(TaskPage);
